module.exports = /*
 * Generated by PEG.js 0.10.0.
 *
 * http://pegjs.org/
 */
(function() {
  "use strict";

  function peg$subclass(child, parent) {
    function ctor() { this.constructor = child; }
    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
  }

  function peg$SyntaxError(message, expected, found, location) {
    this.message  = message;
    this.expected = expected;
    this.found    = found;
    this.location = location;
    this.name     = "SyntaxError";

    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, peg$SyntaxError);
    }
  }

  peg$subclass(peg$SyntaxError, Error);

  peg$SyntaxError.buildMessage = function(expected, found) {
    var DESCRIBE_EXPECTATION_FNS = {
          literal: function(expectation) {
            return "\"" + literalEscape(expectation.text) + "\"";
          },

          "class": function(expectation) {
            var escapedParts = "",
                i;

            for (i = 0; i < expectation.parts.length; i++) {
              escapedParts += expectation.parts[i] instanceof Array
                ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
                : classEscape(expectation.parts[i]);
            }

            return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
          },

          any: function(expectation) {
            return "any character";
          },

          end: function(expectation) {
            return "end of input";
          },

          other: function(expectation) {
            return expectation.description;
          }
        };

    function hex(ch) {
      return ch.charCodeAt(0).toString(16).toUpperCase();
    }

    function literalEscape(s) {
      return s
        .replace(/\\/g, '\\\\')
        .replace(/"/g,  '\\"')
        .replace(/\0/g, '\\0')
        .replace(/\t/g, '\\t')
        .replace(/\n/g, '\\n')
        .replace(/\r/g, '\\r')
        .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
        .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
    }

    function classEscape(s) {
      return s
        .replace(/\\/g, '\\\\')
        .replace(/\]/g, '\\]')
        .replace(/\^/g, '\\^')
        .replace(/-/g,  '\\-')
        .replace(/\0/g, '\\0')
        .replace(/\t/g, '\\t')
        .replace(/\n/g, '\\n')
        .replace(/\r/g, '\\r')
        .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
        .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
    }

    function describeExpectation(expectation) {
      return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
    }

    function describeExpected(expected) {
      var descriptions = new Array(expected.length),
          i, j;

      for (i = 0; i < expected.length; i++) {
        descriptions[i] = describeExpectation(expected[i]);
      }

      descriptions.sort();

      if (descriptions.length > 0) {
        for (i = 1, j = 1; i < descriptions.length; i++) {
          if (descriptions[i - 1] !== descriptions[i]) {
            descriptions[j] = descriptions[i];
            j++;
          }
        }
        descriptions.length = j;
      }

      switch (descriptions.length) {
        case 1:
          return descriptions[0];

        case 2:
          return descriptions[0] + " or " + descriptions[1];

        default:
          return descriptions.slice(0, -1).join(", ")
            + ", or "
            + descriptions[descriptions.length - 1];
      }
    }

    function describeFound(found) {
      return found ? "\"" + literalEscape(found) + "\"" : "end of input";
    }

    return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
  };

  function peg$parse(input, options) {
    options = options !== void 0 ? options : {};

    var peg$FAILED = {},

        peg$startRuleFunctions = { latex: peg$parselatex },
        peg$startRuleFunction  = peg$parselatex,

        peg$c0 = function() { error("expected \\begin{document}") },
        peg$c1 = function() { error("\\end{document} missing") },
        peg$c2 = peg$anyExpectation(),
        peg$c3 = function() { return g; },
        peg$c4 = function() { g.macro("documentclass", [null, g.documentClass, null]); return true; },
        peg$c5 = "document",
        peg$c6 = peg$literalExpectation("document", false),
        peg$c7 = function() { g.startBalanced(); g.enterGroup(); return true; },
        peg$c8 = function(pars) {
                g.exitGroup();
                g.isBalanced() || error("groups need to be balanced!");
                var l = g.endBalanced();
                // this error should be impossible, it's just to be safe
                l == 1 && g.isBalanced() || error("grammar error: " + l + " levels of balancing are remaining, or the last level is unbalanced!");

                g.createDocument(pars);
                g.logUndefinedRefs();
                return g;
            },
        peg$c9 = function(bb, n, txt, be) {
                bb.length > 0 && g.break();
                var p = g.create(g.par, txt, n ? "noindent" : "");
                be && g.break();
                return p;
            },
        peg$c10 = function() { return g.create(g.linebreak); },
        peg$c11 = function() { return undefined; },
        peg$c12 = function() { return g.createText(g.sp); },
        peg$c13 = peg$otherExpectation("text"),
        peg$c14 = function(p) { return g.createText(p.join("")); },
        peg$c15 = function(m) { return m; },
        peg$c16 = function() { g.enterGroup(true); return true; },
        peg$c17 = function(s) { return g.createText(s); },
        peg$c18 = function() { if (!g.isBalanced()) { g.exitGroup(); return true; } },
        peg$c19 = peg$otherExpectation("primitive"),
        peg$c20 = function() { return g.sp; },
        peg$c21 = function(b) { return !g.isBalanced() },
        peg$c22 = function(b) { return b; },
        peg$c23 = function(e) { return e; },
        peg$c24 = function(m) { g.break(); return m; },
        peg$c25 = function(id) { return g.isPreamble(id); },
        peg$c26 = function(id) { return g.isVmode(id); },
        peg$c27 = function(id) { return g.isHmode(id); },
        peg$c28 = function(id) { return g.isHVmode(id); },
        peg$c29 = function(id) { return g.isHmode(id) || g.isHVmode(id); },
        peg$c30 = function(name) { if (g.hasMacro(name)) { g.beginArgs(name); return true; } },
        peg$c31 = function(name) {
                var args = g.parsedArgs();
                g.endArgs();
                return g.createFragment(g.macro(name, args));
            },
        peg$c32 = function(m) { error("macro only allowed in preamble: " + m); },
        peg$c33 = function(m) { error("unknown macro: \\" + m); },
        peg$c34 = peg$otherExpectation("identifier"),
        peg$c35 = /^[\-$&_\/@]/,
        peg$c36 = peg$classExpectation(["-", "$", "&", "_", "/", "@"], false, false),
        peg$c37 = /^[=,]/,
        peg$c38 = peg$classExpectation(["=", ","], false, false),
        peg$c39 = peg$otherExpectation("key=value"),
        peg$c40 = "=",
        peg$c41 = peg$literalExpectation("=", false),
        peg$c42 = function(k) { error("value expected") },
        peg$c43 = function(k, v) { return v.trim(); },
        peg$c44 = function(k, v) { return { [k.trim()]: v == null ? true : v }; },
        peg$c45 = function() { return g.nextArg("X") },
        peg$c46 = function() { g.preExecMacro(); },
        peg$c47 = function() { return g.nextArg("g") },
        peg$c48 = function() { g.argError("group argument expected") },
        peg$c49 = function(a) { g.addParsedArg(a); },
        peg$c50 = function() { return g.nextArg("hg") },
        peg$c51 = function() { return g.nextArg("h") },
        peg$c52 = function() { g.argError("horizontal material expected") },
        peg$c53 = function(h) { g.addParsedArg(h); },
        peg$c54 = function() { return g.nextArg("o?") },
        peg$c55 = function(o) { g.addParsedArg(o); },
        peg$c56 = function() { return g.nextArg("i") },
        peg$c57 = function() { g.argError("id group argument expected") },
        peg$c58 = function(i) { g.addParsedArg(i); },
        peg$c59 = function() { return g.nextArg("i?") },
        peg$c60 = function() { return g.nextArg("k") },
        peg$c61 = function() { g.argError("key group argument expected") },
        peg$c62 = function(k) { g.addParsedArg(k); },
        peg$c63 = function() { return g.nextArg("k?") },
        peg$c64 = function() { return g.nextArg("kv?") },
        peg$c65 = function() { return g.nextArg("csv") },
        peg$c66 = function() { g.argError("comma-sep. values group expected") },
        peg$c67 = function(v) { g.addParsedArg(v); },
        peg$c68 = function() { return g.nextArg("n") },
        peg$c69 = function() { g.argError("num group argument expected") },
        peg$c70 = function(n) { g.addParsedArg(n); },
        peg$c71 = function() { return g.nextArg("n?") },
        peg$c72 = function() { return g.nextArg("l") },
        peg$c73 = function() { g.argError("length group argument expected") },
        peg$c74 = function(l) { g.addParsedArg(l); },
        peg$c75 = function() { return g.nextArg("lg?") },
        peg$c76 = function() { return g.nextArg("l?") },
        peg$c77 = function() { return g.nextArg("m") },
        peg$c78 = function() { g.argError("macro group argument expected") },
        peg$c79 = function(m) { g.addParsedArg(m); },
        peg$c80 = function() { return g.nextArg("u") },
        peg$c81 = function() { g.argError("url group argument expected") },
        peg$c82 = function(u) { g.addParsedArg(u); },
        peg$c83 = function() { return g.nextArg("c") },
        peg$c84 = function() { g.argError("color group expected") },
        peg$c85 = function(c) { g.addParsedArg(c); },
        peg$c86 = function() { return g.nextArg("cl") },
        peg$c87 = function() { g.argError("coordinate/length group expected") },
        peg$c88 = function() { return g.nextArg("cl?") },
        peg$c89 = function() { return g.nextArg("v") },
        peg$c90 = function() { g.argError("coordinate pair expected") },
        peg$c91 = function() { return g.nextArg("v?") },
        peg$c92 = function() { return g.nextArg("cols") },
        peg$c93 = function() { g.argError("column specification missing") },
        peg$c94 = function() { return g.nextArg("is") },
        peg$c95 = function() { return g.nextArg("items") },
        peg$c96 = function() { return g.nextArg("enumitems") },
        peg$c97 = function() { return g.nextArg("s") },
        peg$c98 = "*",
        peg$c99 = peg$literalExpectation("*", false),
        peg$c100 = function(s) { g.addParsedArg(!!s); return !!s; },
        peg$c101 = function(id) { return id; },
        peg$c102 = function(k) { return k; },
        peg$c103 = ",",
        peg$c104 = peg$literalExpectation(",", false),
        peg$c105 = function() {return null;},
        peg$c106 = function(kv) {return kv;},
        peg$c107 = function(kv_list) {
                                return kv_list.filter(kv => kv != null);
                            },
        peg$c108 = function(v) {return v.trim();},
        peg$c109 = function(v_list) {
                                return v_list.filter(v => v != null);
                            },
        peg$c110 = "sp",
        peg$c111 = peg$literalExpectation("sp", false),
        peg$c112 = "pt",
        peg$c113 = peg$literalExpectation("pt", false),
        peg$c114 = "px",
        peg$c115 = peg$literalExpectation("px", false),
        peg$c116 = "dd",
        peg$c117 = peg$literalExpectation("dd", false),
        peg$c118 = "mm",
        peg$c119 = peg$literalExpectation("mm", false),
        peg$c120 = "pc",
        peg$c121 = peg$literalExpectation("pc", false),
        peg$c122 = "cc",
        peg$c123 = peg$literalExpectation("cc", false),
        peg$c124 = "cm",
        peg$c125 = peg$literalExpectation("cm", false),
        peg$c126 = "in",
        peg$c127 = peg$literalExpectation("in", false),
        peg$c128 = "ex",
        peg$c129 = peg$literalExpectation("ex", false),
        peg$c130 = "em",
        peg$c131 = peg$literalExpectation("em", false),
        peg$c132 = function(u) { return u; },
        peg$c133 = function(l, u) { return new g.Length(l, u); },
        peg$c134 = function(l) { return l; },
        peg$c135 = function(n) { return n; },
        peg$c136 = function() { return f; },
        peg$c137 = function(c) { return c; },
        peg$c138 = function(f) { return g.length("unitlength").mul(f) },
        peg$c139 = "(",
        peg$c140 = peg$literalExpectation("(", false),
        peg$c141 = ")",
        peg$c142 = peg$literalExpectation(")", false),
        peg$c143 = function(x, y) { return new Vector(x, y); },
        peg$c144 = "%",
        peg$c145 = peg$literalExpectation("%", false),
        peg$c146 = function(p) { return p; },
        peg$c147 = /^[\-._~:\/?#[\]@!$&()*+,;=]/,
        peg$c148 = peg$classExpectation(["-", ".", "_", "~", ":", "/", "?", "#", "[", "]", "@", "!", "$", "&", "(", ")", "*", "+", ",", ";", "="], false, false),
        peg$c149 = "'",
        peg$c150 = peg$literalExpectation("'", false),
        peg$c151 = function() { error("illegal char in url given"); },
        peg$c152 = function(c) {return c;},
        peg$c153 = function(url) { return url.join(""); },
        peg$c154 = function() { g.enterGroup(); g.startBalanced(); return true; },
        peg$c155 = function(s, p) {
                                g.isBalanced() || error("groups inside an argument need to be balanced!");
                                g.endBalanced();
                                g.exitGroup();

                                s != undefined && p.unshift(g.createText(s));
                                return g.createFragment(p);
                            },
        peg$c156 = function(l) { return g.createFragment(l); },
        peg$c157 = function(s, h) {
                                g.isBalanced() || error("groups inside an argument need to be balanced!");
                                g.endBalanced();
                                g.exitGroup();
                                return g.createFragment(g.createText(s), h);
                            },
        peg$c158 = function(p) { return g.isBalanced(); },
        peg$c159 = function(p) {
                                g.isBalanced() || error("groups inside an optional argument need to be balanced!");
                                g.endBalanced();
                                g.exitGroup();
                                return g.createFragment(p);
                            },
        peg$c160 = "value",
        peg$c161 = peg$literalExpectation("value", false),
        peg$c162 = "real",
        peg$c163 = peg$literalExpectation("real", false),
        peg$c164 = function(f) { return f; },
        peg$c165 = function(expr) { return expr; },
        peg$c166 = function(c) { return g.counter(c); },
        peg$c167 = "+",
        peg$c168 = peg$literalExpectation("+", false),
        peg$c169 = "-",
        peg$c170 = peg$literalExpectation("-", false),
        peg$c171 = function(s, n) { return s == "-" ? -n : n; },
        peg$c172 = "/",
        peg$c173 = peg$literalExpectation("/", false),
        peg$c174 = function(head, tail) {
                            var result = head, i;

                            for (i = 0; i < tail.length; i++) {
                                if (tail[i][1] === "*") { result = Math.trunc(result * tail[i][3]); }
                                if (tail[i][1] === "/") { result = Math.trunc(result / tail[i][3]); }
                            }

                            return Math.trunc(result);
                        },
        peg$c175 = function(head, tail) {
                            var result = head, i;

                            for (i = 0; i < tail.length; i++) {
                                if (tail[i][1] === "+") { result += tail[i][3]; }
                                if (tail[i][1] === "-") { result -= tail[i][3]; }
                            }

                            return result;
                        },
        peg$c176 = ":",
        peg$c177 = peg$literalExpectation(":", false),
        peg$c178 = "!",
        peg$c179 = peg$literalExpectation("!", false),
        peg$c180 = ">",
        peg$c181 = peg$literalExpectation(">", false),
        peg$c182 = "wheel",
        peg$c183 = peg$literalExpectation("wheel", false),
        peg$c184 = "twheel",
        peg$c185 = peg$literalExpectation("twheel", false),
        peg$c186 = function(m) { return m.length % 2 == 0; },
        peg$c187 = ".",
        peg$c188 = peg$literalExpectation(".", false),
        peg$c189 = "!!",
        peg$c190 = peg$literalExpectation("!!", false),
        peg$c191 = "[",
        peg$c192 = peg$literalExpectation("[", false),
        peg$c193 = "]",
        peg$c194 = peg$literalExpectation("]", false),
        peg$c195 = "rgb",
        peg$c196 = peg$literalExpectation("rgb", false),
        peg$c197 = "cmy",
        peg$c198 = peg$literalExpectation("cmy", false),
        peg$c199 = "cmyk",
        peg$c200 = peg$literalExpectation("cmyk", false),
        peg$c201 = "hsb",
        peg$c202 = peg$literalExpectation("hsb", false),
        peg$c203 = "gray",
        peg$c204 = peg$literalExpectation("gray", false),
        peg$c205 = "RBG",
        peg$c206 = peg$literalExpectation("RBG", false),
        peg$c207 = "HTML",
        peg$c208 = peg$literalExpectation("HTML", false),
        peg$c209 = "HSB",
        peg$c210 = peg$literalExpectation("HSB", false),
        peg$c211 = "Gray",
        peg$c212 = peg$literalExpectation("Gray", false),
        peg$c213 = "Hsb",
        peg$c214 = peg$literalExpectation("Hsb", false),
        peg$c215 = "tHsb",
        peg$c216 = peg$literalExpectation("tHsb", false),
        peg$c217 = "wave",
        peg$c218 = peg$literalExpectation("wave", false),
        peg$c219 = "named",
        peg$c220 = peg$literalExpectation("named", false),
        peg$c221 = "ps",
        peg$c222 = peg$literalExpectation("ps", false),
        peg$c223 = function(s, _c, _s) { return Array.isArray(_c) ? _c.concat(_s) : [_c].concat(_s); },
        peg$c224 = function(s, c) {
                return c.reduce(function(a, b) { return a.concat(b); }, s)
            },
        peg$c225 = "l",
        peg$c226 = peg$literalExpectation("l", false),
        peg$c227 = "c",
        peg$c228 = peg$literalExpectation("c", false),
        peg$c229 = "r",
        peg$c230 = peg$literalExpectation("r", false),
        peg$c231 = "p",
        peg$c232 = peg$literalExpectation("p", false),
        peg$c233 = function(c) {
                return c;
            },
        peg$c234 = function(reps, c) {
                var result = []
                for (var i = 0; i < reps; i++) {
                    result = result.concat(c.slice())
                }
                return result
            },
        peg$c235 = "|",
        peg$c236 = peg$literalExpectation("|", false),
        peg$c237 = "@",
        peg$c238 = peg$literalExpectation("@", false),
        peg$c239 = function(a) { return a; },
        peg$c240 = function(s) {
                return {
                    type: "separator",
                    content: s
                }
            },
        peg$c241 = "vspace",
        peg$c242 = peg$literalExpectation("vspace", false),
        peg$c243 = function(l) { return g.createVSpaceInline(l); },
        peg$c244 = function(l) { return g.createVSpace(l); },
        peg$c245 = "small",
        peg$c246 = peg$literalExpectation("small", false),
        peg$c247 = "med",
        peg$c248 = peg$literalExpectation("med", false),
        peg$c249 = "big",
        peg$c250 = peg$literalExpectation("big", false),
        peg$c251 = "skip",
        peg$c252 = peg$literalExpectation("skip", false),
        peg$c253 = function(s) { return g.createVSpaceSkipInline(s + "skip"); },
        peg$c254 = function(s) { return g.createVSpaceSkip(s + "skip"); },
        peg$c255 = "verb",
        peg$c256 = peg$literalExpectation("verb", false),
        peg$c257 = function(s, b, t) { return b == t; },
        peg$c258 = function(s, b, v, e) {
                                b == e || error("\\verb is missing its end delimiter: " + b);
                                if (s)
                                    v = v.replace(/ /g, g.visp);

                                return g.create(g.verb, g.createVerbatim(v, true));
                            },
        peg$c259 = peg$otherExpectation("\\begin"),
        peg$c260 = function(id) { g.begin(id); return id; },
        peg$c261 = function(id, s) {
                return { id, end: id + (s ? "*" : "") };
            },
        peg$c262 = peg$otherExpectation("\\end"),
        peg$c263 = function(id, s) {
                return id + (s ? "*" : "");
            },
        peg$c264 = function(id) { return g.macro(id.id, g.endArgs()); },
        peg$c265 = function(id, node, s) {return g.createText(s); },
        peg$c266 = function(id, node, sb, p, end_id, s) {return g.createText(s); },
        peg$c267 = function(id, node, sb, p, end_id, se) {
                var end = g.end(id.end, end_id);

                // if nodes are created by macro, add content as children to the last element
                // if node is a text node, just add it
                // potential spaces after \begin and \end have to be added explicitely

                var pf = g.createFragment(p);
                if (pf && node && node.length > 0 && node[node.length - 1].nodeType === 1) {
                    node[node.length - 1].appendChild(sb);
                    node[node.length - 1].appendChild(pf);
                    return g.createFragment(node, end, se);
                }

                return g.createFragment(node, sb, pf, end, se);     // use pf, fragments in p are now empty!!
            },
        peg$c268 = function(id) { g.break(); },
        peg$c269 = function(id, node, p, end_id) {
                var end = g.end(id.end, end_id);

                // if nodes are created by macro, add content as children to the last element
                // if node is a text node, just add it

                var pf = g.createFragment(p);
                if (pf && node && node.length > 0 && node[node.length - 1].nodeType === 1) {
                    node[node.length - 1].appendChild(pf);
                    return g.createFragment(node, end);
                }
                return g.createFragment(node, pf, end);
            },
        peg$c270 = "item",
        peg$c271 = peg$literalExpectation("item", false),
        peg$c272 = function() { g.break(); },
        peg$c273 = function(og) { return og; },
        peg$c274 = function(label, p) { return p; },
        peg$c275 = function(label, pars) {
                    return {
                        label: label,
                        text: g.createFragment(pars)
                    };
                },
        peg$c276 = function(label) {
                    // null is no opt_group (\item ...)
                    // undefined is an empty one (\item[] ...)
                    if (label === null) {
                        var itemCounter = "enum" + g.roman(g.counter("@enumdepth"));
                        var itemId = "item-" + g.nextId();
                        g.stepCounter(itemCounter);
                        g.refCounter(itemCounter, itemId);
                        return {
                            id:   itemId,
                            node: g.macro("label" + itemCounter)
                        };
                    }
                    return {
                        id: undefined,
                        node: label
                    };
                },
        peg$c277 = peg$otherExpectation("comment environment"),
        peg$c278 = "\\begin",
        peg$c279 = peg$literalExpectation("\\begin", false),
        peg$c280 = "{comment}",
        peg$c281 = peg$literalExpectation("{comment}", false),
        peg$c282 = function() { g.break(); return undefined; },
        peg$c283 = "\\end",
        peg$c284 = peg$literalExpectation("\\end", false),
        peg$c285 = function(m) { return g.parseMath(m, false); },
        peg$c286 = function(m) { return g.parseMath(m, true); },
        peg$c287 = "begin",
        peg$c288 = peg$literalExpectation("begin", false),
        peg$c289 = function() {},
        peg$c290 = "end",
        peg$c291 = peg$literalExpectation("end", false),
        peg$c292 = "par",
        peg$c293 = peg$literalExpectation("par", false),
        peg$c294 = "noindent",
        peg$c295 = peg$literalExpectation("noindent", false),
        peg$c296 = "plus",
        peg$c297 = peg$literalExpectation("plus", false),
        peg$c298 = "minus",
        peg$c299 = peg$literalExpectation("minus", false),
        peg$c300 = "endinput",
        peg$c301 = peg$literalExpectation("endinput", false),
        peg$c302 = "\\",
        peg$c303 = peg$literalExpectation("\\", false),
        peg$c304 = "{",
        peg$c305 = peg$literalExpectation("{", false),
        peg$c306 = "}",
        peg$c307 = peg$literalExpectation("}", false),
        peg$c308 = peg$otherExpectation("math"),
        peg$c309 = "$",
        peg$c310 = peg$literalExpectation("$", false),
        peg$c311 = "&",
        peg$c312 = peg$literalExpectation("&", false),
        peg$c313 = peg$otherExpectation("parameter"),
        peg$c314 = "#",
        peg$c315 = peg$literalExpectation("#", false),
        peg$c316 = "^",
        peg$c317 = peg$literalExpectation("^", false),
        peg$c318 = "_",
        peg$c319 = peg$literalExpectation("_", false),
        peg$c320 = "\0",
        peg$c321 = peg$literalExpectation("\0", false),
        peg$c322 = peg$otherExpectation("EOF"),
        peg$c323 = peg$otherExpectation("newline"),
        peg$c324 = "\n",
        peg$c325 = peg$literalExpectation("\n", false),
        peg$c326 = "\r\n",
        peg$c327 = peg$literalExpectation("\r\n", false),
        peg$c328 = "\r",
        peg$c329 = peg$literalExpectation("\r", false),
        peg$c330 = "\u2028",
        peg$c331 = peg$literalExpectation("\u2028", false),
        peg$c332 = "\u2029",
        peg$c333 = peg$literalExpectation("\u2029", false),
        peg$c334 = peg$otherExpectation("whitespace"),
        peg$c335 = /^[ \t]/,
        peg$c336 = peg$classExpectation([" ", "\t"], false, false),
        peg$c337 = peg$otherExpectation("comment"),
        peg$c338 = peg$otherExpectation("spaces"),
        peg$c339 = function() { return g.brsp; },
        peg$c340 = peg$otherExpectation("control space"),
        peg$c341 = peg$otherExpectation("non-brk space"),
        peg$c342 = "~",
        peg$c343 = peg$literalExpectation("~", false),
        peg$c344 = function() { return g.nbsp; },
        peg$c345 = peg$otherExpectation("paragraph break"),
        peg$c346 = function() { return true; },
        peg$c347 = peg$otherExpectation("linebreak"),
        peg$c348 = function(l) {return l;},
        peg$c349 = function(l) {
                                          if (l) return g.createBreakSpace(l);
                                          else   return g.create(g.linebreak);
                                      },
        peg$c350 = "put",
        peg$c351 = peg$literalExpectation("put", false),
        peg$c352 = "newline",
        peg$c353 = peg$literalExpectation("newline", false),
        peg$c354 = peg$otherExpectation("letter"),
        peg$c355 = /^[a-z]/i,
        peg$c356 = peg$classExpectation([["a", "z"]], false, true),
        peg$c357 = function(c) { return g.character(c); },
        peg$c358 = peg$otherExpectation("digit"),
        peg$c359 = /^[0-9]/,
        peg$c360 = peg$classExpectation([["0", "9"]], false, false),
        peg$c361 = function(n) { return g.character(n); },
        peg$c362 = peg$otherExpectation("punctuation"),
        peg$c363 = /^[.,;:*\/()!?=+<>]/,
        peg$c364 = peg$classExpectation([".", ",", ";", ":", "*", "/", "(", ")", "!", "?", "=", "+", "<", ">"], false, false),
        peg$c365 = function(p) { return g.character(p); },
        peg$c366 = peg$otherExpectation("quotes"),
        peg$c367 = /^[`']/,
        peg$c368 = peg$classExpectation(["`", "'"], false, false),
        peg$c369 = function(q) { return g.textquote(q); },
        peg$c370 = peg$otherExpectation("left bracket"),
        peg$c371 = function(b) { return g.character(b); },
        peg$c372 = peg$otherExpectation("right bracket"),
        peg$c373 = peg$otherExpectation("utf8 char"),
        peg$c374 = function(u) { return g.character(u); },
        peg$c375 = peg$otherExpectation("hyphen"),
        peg$c376 = function() { return g.hyphen(); },
        peg$c377 = peg$otherExpectation("ligature"),
        peg$c378 = "ffi",
        peg$c379 = peg$literalExpectation("ffi", false),
        peg$c380 = "ffl",
        peg$c381 = peg$literalExpectation("ffl", false),
        peg$c382 = "ff",
        peg$c383 = peg$literalExpectation("ff", false),
        peg$c384 = "fi",
        peg$c385 = peg$literalExpectation("fi", false),
        peg$c386 = "fl",
        peg$c387 = peg$literalExpectation("fl", false),
        peg$c388 = "---",
        peg$c389 = peg$literalExpectation("---", false),
        peg$c390 = "--",
        peg$c391 = peg$literalExpectation("--", false),
        peg$c392 = "``",
        peg$c393 = peg$literalExpectation("``", false),
        peg$c394 = "''",
        peg$c395 = peg$literalExpectation("''", false),
        peg$c396 = "!\xB4",
        peg$c397 = peg$literalExpectation("!\xB4", false),
        peg$c398 = "?\xB4",
        peg$c399 = peg$literalExpectation("?\xB4", false),
        peg$c400 = "<<",
        peg$c401 = peg$literalExpectation("<<", false),
        peg$c402 = ">>",
        peg$c403 = peg$literalExpectation(">>", false),
        peg$c404 = function(l) { return g.ligature(l); },
        peg$c405 = peg$otherExpectation("control symbol"),
        peg$c406 = /^[$%#&{}_\-,\/@]/,
        peg$c407 = peg$classExpectation(["$", "%", "#", "&", "{", "}", "_", "-", ",", "/", "@"], false, false),
        peg$c408 = function(c) { return g.controlSymbol(c); },
        peg$c409 = peg$otherExpectation("symbol macro"),
        peg$c410 = function(name) { return g.hasSymbol(name); },
        peg$c411 = function(name) {
                return g.symbol(name);
            },
        peg$c412 = peg$otherExpectation("diacritic macro"),
        peg$c413 = function(d) { return g.hasDiacritic(d); },
        peg$c414 = function(d, c, s) { return g.diacritic(d, c) + (s ? s:""); },
        peg$c415 = function(d, c) { return g.diacritic(d, c); },
        peg$c416 = function(d, c) {
                return c;
            },
        peg$c417 = "symbol",
        peg$c418 = peg$literalExpectation("symbol", false),
        peg$c419 = function(i) { return String.fromCharCode(i); },
        peg$c420 = "char",
        peg$c421 = peg$literalExpectation("char", false),
        peg$c422 = "^^^^",
        peg$c423 = peg$literalExpectation("^^^^", false),
        peg$c424 = "^^",
        peg$c425 = peg$literalExpectation("^^", false),
        peg$c426 = function(c) { c = c.charCodeAt(0);
                                                                          return String.fromCharCode(c < 64 ? c + 64 : c - 64); },
        peg$c427 = function(i) { return parseInt(i, 10); },
        peg$c428 = function(o) { return parseInt(o, 8); },
        peg$c429 = "\"",
        peg$c430 = peg$literalExpectation("\"", false),
        peg$c431 = function(h) { return h; },
        peg$c432 = peg$otherExpectation("8bit hex value"),
        peg$c433 = function(h) { return parseInt(h, 16); },
        peg$c434 = peg$otherExpectation("16bit hex value"),
        peg$c435 = peg$otherExpectation("integer value"),
        peg$c436 = peg$otherExpectation("octal value"),
        peg$c437 = /^[0-7]/,
        peg$c438 = peg$classExpectation([["0", "7"]], false, false),
        peg$c439 = peg$otherExpectation("hex digit"),
        peg$c440 = /^[a-f0-9]/i,
        peg$c441 = peg$classExpectation([["a", "f"], ["0", "9"]], false, true),
        peg$c442 = peg$otherExpectation("float value"),
        peg$c443 = /^[+\-]/,
        peg$c444 = peg$classExpectation(["+", "-"], false, false),
        peg$c445 = function(f) { return parseFloat(f); },
        peg$c446 = "the",
        peg$c447 = peg$literalExpectation("the", false),
        peg$c448 = function(c) { return g.hasCounter(c);},
        peg$c449 = function(c) { return g.createText("" + g.counter(c)); },
        peg$c450 = function(id) { return g.theLength(id); },
        peg$c451 = function(t) { return t; },
        peg$c452 = "showthe",
        peg$c453 = peg$literalExpectation("showthe", false),
        peg$c454 = function(c) { console.log(g.counter(c)); },
        peg$c455 = function(l) { console.log(g.length(l)); },
        peg$c456 = "message",
        peg$c457 = peg$literalExpectation("message", false),
        peg$c458 = function(m) { console.log(m.textContent); },

        peg$currPos          = 0,
        peg$savedPos         = 0,
        peg$posDetailsCache  = [{ line: 1, column: 1 }],
        peg$maxFailPos       = 0,
        peg$maxFailExpected  = [],
        peg$silentFails      = 0,

        peg$result;

    if ("startRule" in options) {
      if (!(options.startRule in peg$startRuleFunctions)) {
        throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
      }

      peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
    }

    function text() {
      return input.substring(peg$savedPos, peg$currPos);
    }

    function location() {
      return peg$computeLocation(peg$savedPos, peg$currPos);
    }

    function expected(description, location) {
      location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

      throw peg$buildStructuredError(
        [peg$otherExpectation(description)],
        input.substring(peg$savedPos, peg$currPos),
        location
      );
    }

    function error(message, location) {
      location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

      throw peg$buildSimpleError(message, location);
    }

    function peg$literalExpectation(text, ignoreCase) {
      return { type: "literal", text: text, ignoreCase: ignoreCase };
    }

    function peg$classExpectation(parts, inverted, ignoreCase) {
      return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
    }

    function peg$anyExpectation() {
      return { type: "any" };
    }

    function peg$endExpectation() {
      return { type: "end" };
    }

    function peg$otherExpectation(description) {
      return { type: "other", description: description };
    }

    function peg$computePosDetails(pos) {
      var details = peg$posDetailsCache[pos], p;

      if (details) {
        return details;
      } else {
        p = pos - 1;
        while (!peg$posDetailsCache[p]) {
          p--;
        }

        details = peg$posDetailsCache[p];
        details = {
          line:   details.line,
          column: details.column
        };

        while (p < pos) {
          if (input.charCodeAt(p) === 10) {
            details.line++;
            details.column = 1;
          } else {
            details.column++;
          }

          p++;
        }

        peg$posDetailsCache[pos] = details;
        return details;
      }
    }

    function peg$computeLocation(startPos, endPos) {
      var startPosDetails = peg$computePosDetails(startPos),
          endPosDetails   = peg$computePosDetails(endPos);

      return {
        start: {
          offset: startPos,
          line:   startPosDetails.line,
          column: startPosDetails.column
        },
        end: {
          offset: endPos,
          line:   endPosDetails.line,
          column: endPosDetails.column
        }
      };
    }

    function peg$fail(expected) {
      if (peg$currPos < peg$maxFailPos) { return; }

      if (peg$currPos > peg$maxFailPos) {
        peg$maxFailPos = peg$currPos;
        peg$maxFailExpected = [];
      }

      peg$maxFailExpected.push(expected);
    }

    function peg$buildSimpleError(message, location) {
      return new peg$SyntaxError(message, null, null, location);
    }

    function peg$buildStructuredError(expected, found, location) {
      return new peg$SyntaxError(
        peg$SyntaxError.buildMessage(expected, found),
        expected,
        found,
        location
      );
    }

    function peg$parselatex() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8;

      s0 = peg$currPos;
      s1 = peg$currPos;
      peg$silentFails++;
      s2 = peg$parsewith_preamble();
      peg$silentFails--;
      if (s2 !== peg$FAILED) {
        peg$currPos = s1;
        s1 = void 0;
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$parseskip_all_space();
        if (s4 !== peg$FAILED) {
          s5 = peg$parseescape();
          if (s5 !== peg$FAILED) {
            s6 = peg$currPos;
            peg$silentFails++;
            s7 = peg$parseis_hvmode();
            peg$silentFails--;
            if (s7 !== peg$FAILED) {
              peg$currPos = s6;
              s6 = void 0;
            } else {
              s6 = peg$FAILED;
            }
            if (s6 === peg$FAILED) {
              s6 = peg$currPos;
              peg$silentFails++;
              s7 = peg$parseis_preamble();
              peg$silentFails--;
              if (s7 !== peg$FAILED) {
                peg$currPos = s6;
                s6 = void 0;
              } else {
                s6 = peg$FAILED;
              }
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parsemacro();
              if (s7 !== peg$FAILED) {
                s4 = [s4, s5, s6, s7];
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parseskip_all_space();
          if (s4 !== peg$FAILED) {
            s5 = peg$parseescape();
            if (s5 !== peg$FAILED) {
              s6 = peg$currPos;
              peg$silentFails++;
              s7 = peg$parseis_hvmode();
              peg$silentFails--;
              if (s7 !== peg$FAILED) {
                peg$currPos = s6;
                s6 = void 0;
              } else {
                s6 = peg$FAILED;
              }
              if (s6 === peg$FAILED) {
                s6 = peg$currPos;
                peg$silentFails++;
                s7 = peg$parseis_preamble();
                peg$silentFails--;
                if (s7 !== peg$FAILED) {
                  peg$currPos = s6;
                  s6 = void 0;
                } else {
                  s6 = peg$FAILED;
                }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parsemacro();
                if (s7 !== peg$FAILED) {
                  s4 = [s4, s5, s6, s7];
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_all_space();
          if (s3 !== peg$FAILED) {
            s4 = peg$parsebegin_doc();
            if (s4 === peg$FAILED) {
              peg$savedPos = peg$currPos;
              s4 = peg$c0();
              if (s4) {
                s4 = void 0;
              } else {
                s4 = peg$FAILED;
              }
            }
            if (s4 !== peg$FAILED) {
              s5 = peg$parsedocument();
              if (s5 !== peg$FAILED) {
                s6 = peg$parseend_doc();
                if (s6 === peg$FAILED) {
                  peg$savedPos = peg$currPos;
                  s6 = peg$c1();
                  if (s6) {
                    s6 = void 0;
                  } else {
                    s6 = peg$FAILED;
                  }
                }
                if (s6 !== peg$FAILED) {
                  s7 = [];
                  if (input.length > peg$currPos) {
                    s8 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c2); }
                  }
                  while (s8 !== peg$FAILED) {
                    s7.push(s8);
                    if (input.length > peg$currPos) {
                      s8 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c2); }
                    }
                  }
                  if (s7 !== peg$FAILED) {
                    s8 = peg$parseEOF();
                    if (s8 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c3();
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$currPos;
        peg$silentFails++;
        s2 = peg$parsewith_preamble();
        peg$silentFails--;
        if (s2 === peg$FAILED) {
          s1 = void 0;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = peg$currPos;
          s2 = peg$c4();
          if (s2) {
            s2 = void 0;
          } else {
            s2 = peg$FAILED;
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parsedocument();
            if (s3 !== peg$FAILED) {
              s4 = peg$parseEOF();
              if (s4 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c3();
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }

      return s0;
    }

    function peg$parsewith_preamble() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = peg$parseskip_all_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parseescape();
        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          peg$silentFails++;
          s4 = peg$parseis_preamble();
          peg$silentFails--;
          if (s4 !== peg$FAILED) {
            peg$currPos = s3;
            s3 = void 0;
          } else {
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s1 = [s1, s2, s3];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsebegin_doc() {
      var s0, s1, s2, s3, s4, s5, s6;

      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s4 = peg$parsebegin_group();
            if (s4 !== peg$FAILED) {
              if (input.substr(peg$currPos, 8) === peg$c5) {
                s5 = peg$c5;
                peg$currPos += 8;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c6); }
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parseend_group();
                if (s6 !== peg$FAILED) {
                  s1 = [s1, s2, s3, s4, s5, s6];
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseend_doc() {
      var s0, s1, s2, s3, s4, s5, s6;

      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        s2 = peg$parseend();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s4 = peg$parsebegin_group();
            if (s4 !== peg$FAILED) {
              if (input.substr(peg$currPos, 8) === peg$c5) {
                s5 = peg$c5;
                peg$currPos += 8;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c6); }
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parseend_group();
                if (s6 !== peg$FAILED) {
                  s1 = [s1, s2, s3, s4, s5, s6];
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsedocument() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      peg$savedPos = peg$currPos;
      s1 = peg$c7();
      if (s1) {
        s1 = void 0;
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parseskip_all_space();
        if (s2 !== peg$FAILED) {
          s3 = [];
          s4 = peg$parseparagraph();
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$parseparagraph();
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseskip_all_space();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c8(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseparagraph() {
      var s0, s1, s2, s3, s4, s5;

      s0 = peg$parsevmode_macro();
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = [];
        s2 = peg$currPos;
        s3 = peg$currPos;
        s4 = peg$parseescape();
        if (s4 !== peg$FAILED) {
          s5 = peg$parsenoindent();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsebreak();
          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$currPos;
          s3 = peg$currPos;
          s4 = peg$parseescape();
          if (s4 !== peg$FAILED) {
            s5 = peg$parsenoindent();
            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
          if (s3 === peg$FAILED) {
            s3 = null;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parsebreak();
            if (s4 !== peg$FAILED) {
              s3 = [s3, s4];
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parseskip_space();
          if (s2 !== peg$FAILED) {
            s3 = peg$currPos;
            s4 = peg$parseescape();
            if (s4 !== peg$FAILED) {
              s5 = peg$parsenoindent();
              if (s5 !== peg$FAILED) {
                s4 = [s4, s5];
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
            if (s3 === peg$FAILED) {
              s3 = null;
            }
            if (s3 !== peg$FAILED) {
              s4 = [];
              s5 = peg$parsetext();
              if (s5 !== peg$FAILED) {
                while (s5 !== peg$FAILED) {
                  s4.push(s5);
                  s5 = peg$parsetext();
                }
              } else {
                s4 = peg$FAILED;
              }
              if (s4 !== peg$FAILED) {
                s5 = peg$parsebreak();
                if (s5 === peg$FAILED) {
                  s5 = null;
                }
                if (s5 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c9(s1, s3, s4, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }

      return s0;
    }

    function peg$parseparagraph_with_linebreak() {
      var s0, s1;

      s0 = peg$parsetext();
      if (s0 === peg$FAILED) {
        s0 = peg$parsevmode_macro();
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parsebreak();
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c10();
          }
          s0 = s1;
        }
      }

      return s0;
    }

    function peg$parseline() {
      var s0, s1;

      s0 = peg$currPos;
      s1 = peg$parselinebreak();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parsebreak();
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c12();
        }
        s0 = s1;
        if (s0 === peg$FAILED) {
          s0 = peg$parsetext();
        }
      }

      return s0;
    }

    function peg$parsetext() {
      var s0, s1, s2, s3, s4;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parseligature();
      if (s2 === peg$FAILED) {
        s2 = peg$parseprimitive();
        if (s2 === peg$FAILED) {
          s2 = peg$currPos;
          s3 = peg$currPos;
          peg$silentFails++;
          s4 = peg$parsebreak();
          peg$silentFails--;
          if (s4 === peg$FAILED) {
            s3 = void 0;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parsecomment();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s2;
              s3 = peg$c11();
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        }
      }
      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$parseligature();
          if (s2 === peg$FAILED) {
            s2 = peg$parseprimitive();
            if (s2 === peg$FAILED) {
              s2 = peg$currPos;
              s3 = peg$currPos;
              peg$silentFails++;
              s4 = peg$parsebreak();
              peg$silentFails--;
              if (s4 === peg$FAILED) {
                s3 = void 0;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parsecomment();
                if (s4 !== peg$FAILED) {
                  peg$savedPos = s2;
                  s3 = peg$c11();
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$FAILED;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            }
          }
        }
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c14(s1);
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$parselinebreak();
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$currPos;
          s2 = peg$currPos;
          peg$silentFails++;
          s3 = peg$parseunskip_macro();
          peg$silentFails--;
          if (s3 !== peg$FAILED) {
            peg$currPos = s2;
            s2 = void 0;
          } else {
            s2 = peg$FAILED;
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parseskip_space();
            if (s3 !== peg$FAILED) {
              s2 = [s2, s3];
              s1 = s2;
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
          if (s1 === peg$FAILED) {
            s1 = null;
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parsehmode_macro();
            if (s2 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c15(s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$parsemath();
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parsebegin_group();
              if (s1 !== peg$FAILED) {
                peg$savedPos = peg$currPos;
                s2 = peg$c16();
                if (s2) {
                  s2 = void 0;
                } else {
                  s2 = peg$FAILED;
                }
                if (s2 !== peg$FAILED) {
                  s3 = peg$parsespace();
                  if (s3 === peg$FAILED) {
                    s3 = null;
                  }
                  if (s3 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c17(s3);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseend_group();
                if (s1 !== peg$FAILED) {
                  peg$savedPos = peg$currPos;
                  s2 = peg$c18();
                  if (s2) {
                    s2 = void 0;
                  } else {
                    s2 = peg$FAILED;
                  }
                  if (s2 !== peg$FAILED) {
                    s3 = peg$parsespace();
                    if (s3 === peg$FAILED) {
                      s3 = null;
                    }
                    if (s3 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c17(s3);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              }
            }
          }
        }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c13); }
      }

      return s0;
    }

    function peg$parseprimitive() {
      var s0, s1, s2, s3;

      peg$silentFails++;
      s0 = peg$parsechar();
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parsespace();
        if (s1 !== peg$FAILED) {
          s2 = peg$currPos;
          peg$silentFails++;
          s3 = peg$parseunskip_macro();
          peg$silentFails--;
          if (s3 === peg$FAILED) {
            s2 = void 0;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c20();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$parsehyphen();
          if (s0 === peg$FAILED) {
            s0 = peg$parsedigit();
            if (s0 === peg$FAILED) {
              s0 = peg$parsepunctuation();
              if (s0 === peg$FAILED) {
                s0 = peg$parsequotes();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseleft_br();
                  if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parseright_br();
                    if (s1 !== peg$FAILED) {
                      peg$savedPos = peg$currPos;
                      s2 = peg$c21(s1);
                      if (s2) {
                        s2 = void 0;
                      } else {
                        s2 = peg$FAILED;
                      }
                      if (s2 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c22(s1);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                    if (s0 === peg$FAILED) {
                      s0 = peg$parsenbsp();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parsectrl_space();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parsediacritic();
                          if (s0 === peg$FAILED) {
                            s0 = peg$parsectrl_sym();
                            if (s0 === peg$FAILED) {
                              s0 = peg$parsesymbol();
                              if (s0 === peg$FAILED) {
                                s0 = peg$parsecharsym();
                                if (s0 === peg$FAILED) {
                                  s0 = peg$parseutf8_char();
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c19); }
      }

      return s0;
    }

    function peg$parsehv_macro() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = peg$parseis_hvmode();
        peg$silentFails--;
        if (s4 !== peg$FAILED) {
          peg$currPos = s3;
          s3 = void 0;
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsemacro();
          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 === peg$FAILED) {
          s2 = peg$parselogging();
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c11();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsehmode_macro() {
      var s0, s1, s2, s3, s4, s5, s6;

      s0 = peg$parsehv_macro();
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseescape();
        if (s1 !== peg$FAILED) {
          s2 = peg$currPos;
          s3 = peg$currPos;
          peg$silentFails++;
          s4 = peg$parseis_hmode();
          peg$silentFails--;
          if (s4 !== peg$FAILED) {
            peg$currPos = s3;
            s3 = void 0;
          } else {
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parsemacro();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s2;
              s3 = peg$c15(s4);
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
          if (s2 === peg$FAILED) {
            s2 = peg$currPos;
            s3 = peg$currPos;
            peg$silentFails++;
            s4 = peg$parseis_hmode_env();
            peg$silentFails--;
            if (s4 !== peg$FAILED) {
              peg$currPos = s3;
              s3 = void 0;
            } else {
              s3 = peg$FAILED;
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parseh_environment();
              if (s4 !== peg$FAILED) {
                peg$savedPos = s2;
                s3 = peg$c23(s4);
                s2 = s3;
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
            if (s2 === peg$FAILED) {
              s2 = peg$parsenoindent();
              if (s2 === peg$FAILED) {
                s2 = peg$parsesmbskip_hmode();
                if (s2 === peg$FAILED) {
                  s2 = peg$parsevspace_hmode();
                  if (s2 === peg$FAILED) {
                    s2 = peg$parsethe();
                    if (s2 === peg$FAILED) {
                      s2 = peg$parseverb();
                      if (s2 === peg$FAILED) {
                        s2 = peg$currPos;
                        s3 = peg$currPos;
                        peg$silentFails++;
                        s4 = peg$parseis_preamble();
                        peg$silentFails--;
                        if (s4 !== peg$FAILED) {
                          peg$currPos = s3;
                          s3 = void 0;
                        } else {
                          s3 = peg$FAILED;
                        }
                        if (s3 !== peg$FAILED) {
                          s4 = peg$parseonly_preamble();
                          if (s4 !== peg$FAILED) {
                            s3 = [s3, s4];
                            s2 = s3;
                          } else {
                            peg$currPos = s2;
                            s2 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s2;
                          s2 = peg$FAILED;
                        }
                        if (s2 === peg$FAILED) {
                          s2 = peg$currPos;
                          s3 = peg$currPos;
                          peg$silentFails++;
                          s4 = peg$parsebegin();
                          peg$silentFails--;
                          if (s4 === peg$FAILED) {
                            s3 = void 0;
                          } else {
                            peg$currPos = s3;
                            s3 = peg$FAILED;
                          }
                          if (s3 !== peg$FAILED) {
                            s4 = peg$currPos;
                            peg$silentFails++;
                            s5 = peg$parseend();
                            peg$silentFails--;
                            if (s5 === peg$FAILED) {
                              s4 = void 0;
                            } else {
                              peg$currPos = s4;
                              s4 = peg$FAILED;
                            }
                            if (s4 !== peg$FAILED) {
                              s5 = peg$currPos;
                              peg$silentFails++;
                              s6 = peg$parseis_vmode();
                              peg$silentFails--;
                              if (s6 === peg$FAILED) {
                                s5 = void 0;
                              } else {
                                peg$currPos = s5;
                                s5 = peg$FAILED;
                              }
                              if (s5 !== peg$FAILED) {
                                s6 = peg$parseunknown_macro();
                                if (s6 !== peg$FAILED) {
                                  s3 = [s3, s4, s5, s6];
                                  s2 = s3;
                                } else {
                                  peg$currPos = s2;
                                  s2 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s2;
                                s2 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s2;
                              s2 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s2;
                            s2 = peg$FAILED;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c15(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }

      return s0;
    }

    function peg$parsevmode_macro() {
      var s0, s1, s2, s3, s4, s5;

      s0 = peg$currPos;
      s1 = peg$parseskip_all_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsehv_macro();
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c11();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseskip_all_space();
        if (s1 !== peg$FAILED) {
          s2 = peg$parseescape();
          if (s2 !== peg$FAILED) {
            s3 = peg$currPos;
            s4 = peg$currPos;
            peg$silentFails++;
            s5 = peg$parseis_vmode();
            peg$silentFails--;
            if (s5 !== peg$FAILED) {
              peg$currPos = s4;
              s4 = void 0;
            } else {
              s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
              s5 = peg$parsemacro();
              if (s5 !== peg$FAILED) {
                peg$savedPos = s3;
                s4 = peg$c24(s5);
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
            if (s3 === peg$FAILED) {
              s3 = peg$currPos;
              s4 = peg$currPos;
              peg$silentFails++;
              s5 = peg$parseis_vmode_env();
              peg$silentFails--;
              if (s5 !== peg$FAILED) {
                peg$currPos = s4;
                s4 = void 0;
              } else {
                s4 = peg$FAILED;
              }
              if (s4 !== peg$FAILED) {
                s5 = peg$parseenvironment();
                if (s5 !== peg$FAILED) {
                  peg$savedPos = s3;
                  s4 = peg$c23(s5);
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
              if (s3 === peg$FAILED) {
                s3 = peg$parsevspace_vmode();
                if (s3 === peg$FAILED) {
                  s3 = peg$parsesmbskip_vmode();
                }
              }
            }
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c15(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }

      return s0;
    }

    function peg$parseis_preamble() {
      var s0, s1, s2;

      s0 = peg$currPos;
      s1 = peg$parseidentifier();
      if (s1 !== peg$FAILED) {
        peg$savedPos = peg$currPos;
        s2 = peg$c25(s1);
        if (s2) {
          s2 = void 0;
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseis_vmode() {
      var s0, s1, s2;

      s0 = peg$currPos;
      s1 = peg$parseidentifier();
      if (s1 !== peg$FAILED) {
        peg$savedPos = peg$currPos;
        s2 = peg$c26(s1);
        if (s2) {
          s2 = void 0;
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseis_hmode() {
      var s0, s1, s2;

      s0 = peg$currPos;
      s1 = peg$parseidentifier();
      if (s1 !== peg$FAILED) {
        peg$savedPos = peg$currPos;
        s2 = peg$c27(s1);
        if (s2) {
          s2 = void 0;
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseis_hvmode() {
      var s0, s1, s2;

      s0 = peg$currPos;
      s1 = peg$parseidentifier();
      if (s1 !== peg$FAILED) {
        peg$savedPos = peg$currPos;
        s2 = peg$c28(s1);
        if (s2) {
          s2 = void 0;
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseis_vmode_env() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = peg$parsebegin();
      if (s1 === peg$FAILED) {
        s1 = peg$parseend();
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_group();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseidentifier();
          if (s3 !== peg$FAILED) {
            peg$savedPos = peg$currPos;
            s4 = peg$c26(s3);
            if (s4) {
              s4 = void 0;
            } else {
              s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
              s1 = [s1, s2, s3, s4];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseis_hmode_env() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = peg$parsebegin();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_group();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseidentifier();
          if (s3 !== peg$FAILED) {
            peg$savedPos = peg$currPos;
            s4 = peg$c29(s3);
            if (s4) {
              s4 = void 0;
            } else {
              s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
              s1 = [s1, s2, s3, s4];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsemacro() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = peg$parseidentifier();
      if (s1 !== peg$FAILED) {
        s2 = peg$parseskip_space();
        if (s2 !== peg$FAILED) {
          peg$savedPos = peg$currPos;
          s3 = peg$c30(s1);
          if (s3) {
            s3 = void 0;
          } else {
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parsemacro_args();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c31(s1);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseonly_preamble() {
      var s0, s1;

      s0 = peg$currPos;
      s1 = peg$parseidentifier();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c32(s1);
      }
      s0 = s1;

      return s0;
    }

    function peg$parseunknown_macro() {
      var s0, s1;

      s0 = peg$currPos;
      s1 = peg$parseidentifier();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c33(s1);
      }
      s0 = s1;

      return s0;
    }

    function peg$parseidentifier() {
      var s0, s1, s2;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parsechar();
      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$parsechar();
        }
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s0 = input.substring(s0, peg$currPos);
      } else {
        s0 = s1;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c34); }
      }

      return s0;
    }

    function peg$parsekey() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parsechar();
      if (s2 === peg$FAILED) {
        s2 = peg$parsedigit();
        if (s2 === peg$FAILED) {
          s2 = peg$parsesp();
          if (s2 === peg$FAILED) {
            if (peg$c35.test(input.charAt(peg$currPos))) {
              s2 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c36); }
            }
            if (s2 === peg$FAILED) {
              s2 = peg$currPos;
              s3 = peg$currPos;
              peg$silentFails++;
              if (peg$c37.test(input.charAt(peg$currPos))) {
                s4 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c38); }
              }
              peg$silentFails--;
              if (s4 === peg$FAILED) {
                s3 = void 0;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
              if (s3 !== peg$FAILED) {
                s4 = peg$parseutf8_char();
                if (s4 !== peg$FAILED) {
                  s3 = [s3, s4];
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$FAILED;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            }
          }
        }
      }
      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$parsechar();
          if (s2 === peg$FAILED) {
            s2 = peg$parsedigit();
            if (s2 === peg$FAILED) {
              s2 = peg$parsesp();
              if (s2 === peg$FAILED) {
                if (peg$c35.test(input.charAt(peg$currPos))) {
                  s2 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s2 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c36); }
                }
                if (s2 === peg$FAILED) {
                  s2 = peg$currPos;
                  s3 = peg$currPos;
                  peg$silentFails++;
                  if (peg$c37.test(input.charAt(peg$currPos))) {
                    s4 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c38); }
                  }
                  peg$silentFails--;
                  if (s4 === peg$FAILED) {
                    s3 = void 0;
                  } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                  }
                  if (s3 !== peg$FAILED) {
                    s4 = peg$parseutf8_char();
                    if (s4 !== peg$FAILED) {
                      s3 = [s3, s4];
                      s2 = s3;
                    } else {
                      peg$currPos = s2;
                      s2 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s2;
                    s2 = peg$FAILED;
                  }
                }
              }
            }
          }
        }
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s0 = input.substring(s0, peg$currPos);
      } else {
        s0 = s1;
      }

      return s0;
    }

    function peg$parsekey_val() {
      var s0, s1, s2, s3, s4, s5, s6;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = peg$parsekey();
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$parseskip_space();
        if (s3 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 61) {
            s4 = peg$c40;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c41); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parseskip_space();
            if (s5 !== peg$FAILED) {
              s6 = peg$parsekey();
              if (s6 === peg$FAILED) {
                peg$savedPos = peg$currPos;
                s6 = peg$c42(s1);
                if (s6) {
                  s6 = void 0;
                } else {
                  s6 = peg$FAILED;
                }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s2;
                s3 = peg$c43(s1, s6);
                s2 = s3;
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 === peg$FAILED) {
          s2 = null;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c44(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c39); }
      }

      return s0;
    }

    function peg$parsemacro_args() {
      var s0, s1, s2, s3;

      s0 = [];
      s1 = peg$currPos;
      peg$savedPos = peg$currPos;
      s2 = peg$c45();
      if (s2) {
        s2 = void 0;
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s1;
        s2 = peg$c46();
      }
      s1 = s2;
      if (s1 === peg$FAILED) {
        s1 = peg$parsenextArgStar();
        if (s1 === peg$FAILED) {
          s1 = peg$currPos;
          peg$savedPos = peg$currPos;
          s2 = peg$c47();
          if (s2) {
            s2 = void 0;
          } else {
            s2 = peg$FAILED;
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parsearg_group();
            if (s3 === peg$FAILED) {
              peg$savedPos = peg$currPos;
              s3 = peg$c48();
              if (s3) {
                s3 = void 0;
              } else {
                s3 = peg$FAILED;
              }
            }
            if (s3 !== peg$FAILED) {
              peg$savedPos = s1;
              s2 = peg$c49(s3);
              s1 = s2;
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
          if (s1 === peg$FAILED) {
            s1 = peg$currPos;
            peg$savedPos = peg$currPos;
            s2 = peg$c50();
            if (s2) {
              s2 = void 0;
            } else {
              s2 = peg$FAILED;
            }
            if (s2 !== peg$FAILED) {
              s3 = peg$parsearg_hgroup();
              if (s3 === peg$FAILED) {
                peg$savedPos = peg$currPos;
                s3 = peg$c48();
                if (s3) {
                  s3 = void 0;
                } else {
                  s3 = peg$FAILED;
                }
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s1;
                s2 = peg$c49(s3);
                s1 = s2;
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
            if (s1 === peg$FAILED) {
              s1 = peg$currPos;
              peg$savedPos = peg$currPos;
              s2 = peg$c51();
              if (s2) {
                s2 = void 0;
              } else {
                s2 = peg$FAILED;
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsehorizontal();
                if (s3 === peg$FAILED) {
                  peg$savedPos = peg$currPos;
                  s3 = peg$c52();
                  if (s3) {
                    s3 = void 0;
                  } else {
                    s3 = peg$FAILED;
                  }
                }
                if (s3 !== peg$FAILED) {
                  peg$savedPos = s1;
                  s2 = peg$c53(s3);
                  s1 = s2;
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
              if (s1 === peg$FAILED) {
                s1 = peg$currPos;
                peg$savedPos = peg$currPos;
                s2 = peg$c54();
                if (s2) {
                  s2 = void 0;
                } else {
                  s2 = peg$FAILED;
                }
                if (s2 !== peg$FAILED) {
                  s3 = peg$parseopt_group();
                  if (s3 === peg$FAILED) {
                    s3 = null;
                  }
                  if (s3 !== peg$FAILED) {
                    peg$savedPos = s1;
                    s2 = peg$c55(s3);
                    s1 = s2;
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
                if (s1 === peg$FAILED) {
                  s1 = peg$currPos;
                  peg$savedPos = peg$currPos;
                  s2 = peg$c56();
                  if (s2) {
                    s2 = void 0;
                  } else {
                    s2 = peg$FAILED;
                  }
                  if (s2 !== peg$FAILED) {
                    s3 = peg$parseid_group();
                    if (s3 === peg$FAILED) {
                      peg$savedPos = peg$currPos;
                      s3 = peg$c57();
                      if (s3) {
                        s3 = void 0;
                      } else {
                        s3 = peg$FAILED;
                      }
                    }
                    if (s3 !== peg$FAILED) {
                      peg$savedPos = s1;
                      s2 = peg$c58(s3);
                      s1 = s2;
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                  if (s1 === peg$FAILED) {
                    s1 = peg$currPos;
                    peg$savedPos = peg$currPos;
                    s2 = peg$c59();
                    if (s2) {
                      s2 = void 0;
                    } else {
                      s2 = peg$FAILED;
                    }
                    if (s2 !== peg$FAILED) {
                      s3 = peg$parseid_optgroup();
                      if (s3 === peg$FAILED) {
                        s3 = null;
                      }
                      if (s3 !== peg$FAILED) {
                        peg$savedPos = s1;
                        s2 = peg$c58(s3);
                        s1 = s2;
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                    if (s1 === peg$FAILED) {
                      s1 = peg$currPos;
                      peg$savedPos = peg$currPos;
                      s2 = peg$c60();
                      if (s2) {
                        s2 = void 0;
                      } else {
                        s2 = peg$FAILED;
                      }
                      if (s2 !== peg$FAILED) {
                        s3 = peg$parsekey_group();
                        if (s3 === peg$FAILED) {
                          peg$savedPos = peg$currPos;
                          s3 = peg$c61();
                          if (s3) {
                            s3 = void 0;
                          } else {
                            s3 = peg$FAILED;
                          }
                        }
                        if (s3 !== peg$FAILED) {
                          peg$savedPos = s1;
                          s2 = peg$c62(s3);
                          s1 = s2;
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                      if (s1 === peg$FAILED) {
                        s1 = peg$currPos;
                        peg$savedPos = peg$currPos;
                        s2 = peg$c63();
                        if (s2) {
                          s2 = void 0;
                        } else {
                          s2 = peg$FAILED;
                        }
                        if (s2 !== peg$FAILED) {
                          s3 = peg$parsekey_optgroup();
                          if (s3 === peg$FAILED) {
                            s3 = null;
                          }
                          if (s3 !== peg$FAILED) {
                            peg$savedPos = s1;
                            s2 = peg$c62(s3);
                            s1 = s2;
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                        if (s1 === peg$FAILED) {
                          s1 = peg$currPos;
                          peg$savedPos = peg$currPos;
                          s2 = peg$c64();
                          if (s2) {
                            s2 = void 0;
                          } else {
                            s2 = peg$FAILED;
                          }
                          if (s2 !== peg$FAILED) {
                            s3 = peg$parsekeyval_optgroup();
                            if (s3 === peg$FAILED) {
                              s3 = null;
                            }
                            if (s3 !== peg$FAILED) {
                              peg$savedPos = s1;
                              s2 = peg$c62(s3);
                              s1 = s2;
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                          if (s1 === peg$FAILED) {
                            s1 = peg$currPos;
                            peg$savedPos = peg$currPos;
                            s2 = peg$c65();
                            if (s2) {
                              s2 = void 0;
                            } else {
                              s2 = peg$FAILED;
                            }
                            if (s2 !== peg$FAILED) {
                              s3 = peg$parsecsv_group();
                              if (s3 === peg$FAILED) {
                                peg$savedPos = peg$currPos;
                                s3 = peg$c66();
                                if (s3) {
                                  s3 = void 0;
                                } else {
                                  s3 = peg$FAILED;
                                }
                              }
                              if (s3 !== peg$FAILED) {
                                peg$savedPos = s1;
                                s2 = peg$c67(s3);
                                s1 = s2;
                              } else {
                                peg$currPos = s1;
                                s1 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                            if (s1 === peg$FAILED) {
                              s1 = peg$currPos;
                              peg$savedPos = peg$currPos;
                              s2 = peg$c68();
                              if (s2) {
                                s2 = void 0;
                              } else {
                                s2 = peg$FAILED;
                              }
                              if (s2 !== peg$FAILED) {
                                s3 = peg$parseexpr_group();
                                if (s3 === peg$FAILED) {
                                  peg$savedPos = peg$currPos;
                                  s3 = peg$c69();
                                  if (s3) {
                                    s3 = void 0;
                                  } else {
                                    s3 = peg$FAILED;
                                  }
                                }
                                if (s3 !== peg$FAILED) {
                                  peg$savedPos = s1;
                                  s2 = peg$c70(s3);
                                  s1 = s2;
                                } else {
                                  peg$currPos = s1;
                                  s1 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s1;
                                s1 = peg$FAILED;
                              }
                              if (s1 === peg$FAILED) {
                                s1 = peg$currPos;
                                peg$savedPos = peg$currPos;
                                s2 = peg$c71();
                                if (s2) {
                                  s2 = void 0;
                                } else {
                                  s2 = peg$FAILED;
                                }
                                if (s2 !== peg$FAILED) {
                                  s3 = peg$parseexpr_optgroup();
                                  if (s3 === peg$FAILED) {
                                    s3 = null;
                                  }
                                  if (s3 !== peg$FAILED) {
                                    peg$savedPos = s1;
                                    s2 = peg$c70(s3);
                                    s1 = s2;
                                  } else {
                                    peg$currPos = s1;
                                    s1 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s1;
                                  s1 = peg$FAILED;
                                }
                                if (s1 === peg$FAILED) {
                                  s1 = peg$currPos;
                                  peg$savedPos = peg$currPos;
                                  s2 = peg$c72();
                                  if (s2) {
                                    s2 = void 0;
                                  } else {
                                    s2 = peg$FAILED;
                                  }
                                  if (s2 !== peg$FAILED) {
                                    s3 = peg$parselength_group();
                                    if (s3 === peg$FAILED) {
                                      peg$savedPos = peg$currPos;
                                      s3 = peg$c73();
                                      if (s3) {
                                        s3 = void 0;
                                      } else {
                                        s3 = peg$FAILED;
                                      }
                                    }
                                    if (s3 !== peg$FAILED) {
                                      peg$savedPos = s1;
                                      s2 = peg$c74(s3);
                                      s1 = s2;
                                    } else {
                                      peg$currPos = s1;
                                      s1 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s1;
                                    s1 = peg$FAILED;
                                  }
                                  if (s1 === peg$FAILED) {
                                    s1 = peg$currPos;
                                    peg$savedPos = peg$currPos;
                                    s2 = peg$c75();
                                    if (s2) {
                                      s2 = void 0;
                                    } else {
                                      s2 = peg$FAILED;
                                    }
                                    if (s2 !== peg$FAILED) {
                                      s3 = peg$parselength_group();
                                      if (s3 === peg$FAILED) {
                                        s3 = null;
                                      }
                                      if (s3 !== peg$FAILED) {
                                        peg$savedPos = s1;
                                        s2 = peg$c74(s3);
                                        s1 = s2;
                                      } else {
                                        peg$currPos = s1;
                                        s1 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s1;
                                      s1 = peg$FAILED;
                                    }
                                    if (s1 === peg$FAILED) {
                                      s1 = peg$currPos;
                                      peg$savedPos = peg$currPos;
                                      s2 = peg$c76();
                                      if (s2) {
                                        s2 = void 0;
                                      } else {
                                        s2 = peg$FAILED;
                                      }
                                      if (s2 !== peg$FAILED) {
                                        s3 = peg$parselength_optgroup();
                                        if (s3 === peg$FAILED) {
                                          s3 = null;
                                        }
                                        if (s3 !== peg$FAILED) {
                                          peg$savedPos = s1;
                                          s2 = peg$c74(s3);
                                          s1 = s2;
                                        } else {
                                          peg$currPos = s1;
                                          s1 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s1;
                                        s1 = peg$FAILED;
                                      }
                                      if (s1 === peg$FAILED) {
                                        s1 = peg$currPos;
                                        peg$savedPos = peg$currPos;
                                        s2 = peg$c77();
                                        if (s2) {
                                          s2 = void 0;
                                        } else {
                                          s2 = peg$FAILED;
                                        }
                                        if (s2 !== peg$FAILED) {
                                          s3 = peg$parsemacro_group();
                                          if (s3 === peg$FAILED) {
                                            peg$savedPos = peg$currPos;
                                            s3 = peg$c78();
                                            if (s3) {
                                              s3 = void 0;
                                            } else {
                                              s3 = peg$FAILED;
                                            }
                                          }
                                          if (s3 !== peg$FAILED) {
                                            peg$savedPos = s1;
                                            s2 = peg$c79(s3);
                                            s1 = s2;
                                          } else {
                                            peg$currPos = s1;
                                            s1 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s1;
                                          s1 = peg$FAILED;
                                        }
                                        if (s1 === peg$FAILED) {
                                          s1 = peg$currPos;
                                          peg$savedPos = peg$currPos;
                                          s2 = peg$c80();
                                          if (s2) {
                                            s2 = void 0;
                                          } else {
                                            s2 = peg$FAILED;
                                          }
                                          if (s2 !== peg$FAILED) {
                                            s3 = peg$parseurl_group();
                                            if (s3 === peg$FAILED) {
                                              peg$savedPos = peg$currPos;
                                              s3 = peg$c81();
                                              if (s3) {
                                                s3 = void 0;
                                              } else {
                                                s3 = peg$FAILED;
                                              }
                                            }
                                            if (s3 !== peg$FAILED) {
                                              peg$savedPos = s1;
                                              s2 = peg$c82(s3);
                                              s1 = s2;
                                            } else {
                                              peg$currPos = s1;
                                              s1 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s1;
                                            s1 = peg$FAILED;
                                          }
                                          if (s1 === peg$FAILED) {
                                            s1 = peg$currPos;
                                            peg$savedPos = peg$currPos;
                                            s2 = peg$c83();
                                            if (s2) {
                                              s2 = void 0;
                                            } else {
                                              s2 = peg$FAILED;
                                            }
                                            if (s2 !== peg$FAILED) {
                                              s3 = peg$parsecolor_group();
                                              if (s3 === peg$FAILED) {
                                                peg$savedPos = peg$currPos;
                                                s3 = peg$c84();
                                                if (s3) {
                                                  s3 = void 0;
                                                } else {
                                                  s3 = peg$FAILED;
                                                }
                                              }
                                              if (s3 !== peg$FAILED) {
                                                peg$savedPos = s1;
                                                s2 = peg$c85(s3);
                                                s1 = s2;
                                              } else {
                                                peg$currPos = s1;
                                                s1 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s1;
                                              s1 = peg$FAILED;
                                            }
                                            if (s1 === peg$FAILED) {
                                              s1 = peg$currPos;
                                              peg$savedPos = peg$currPos;
                                              s2 = peg$c86();
                                              if (s2) {
                                                s2 = void 0;
                                              } else {
                                                s2 = peg$FAILED;
                                              }
                                              if (s2 !== peg$FAILED) {
                                                s3 = peg$parsecoord_group();
                                                if (s3 === peg$FAILED) {
                                                  peg$savedPos = peg$currPos;
                                                  s3 = peg$c87();
                                                  if (s3) {
                                                    s3 = void 0;
                                                  } else {
                                                    s3 = peg$FAILED;
                                                  }
                                                }
                                                if (s3 !== peg$FAILED) {
                                                  peg$savedPos = s1;
                                                  s2 = peg$c85(s3);
                                                  s1 = s2;
                                                } else {
                                                  peg$currPos = s1;
                                                  s1 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s1;
                                                s1 = peg$FAILED;
                                              }
                                              if (s1 === peg$FAILED) {
                                                s1 = peg$currPos;
                                                peg$savedPos = peg$currPos;
                                                s2 = peg$c88();
                                                if (s2) {
                                                  s2 = void 0;
                                                } else {
                                                  s2 = peg$FAILED;
                                                }
                                                if (s2 !== peg$FAILED) {
                                                  s3 = peg$parsecoord_optgroup();
                                                  if (s3 === peg$FAILED) {
                                                    s3 = null;
                                                  }
                                                  if (s3 !== peg$FAILED) {
                                                    peg$savedPos = s1;
                                                    s2 = peg$c85(s3);
                                                    s1 = s2;
                                                  } else {
                                                    peg$currPos = s1;
                                                    s1 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s1;
                                                  s1 = peg$FAILED;
                                                }
                                                if (s1 === peg$FAILED) {
                                                  s1 = peg$currPos;
                                                  peg$savedPos = peg$currPos;
                                                  s2 = peg$c89();
                                                  if (s2) {
                                                    s2 = void 0;
                                                  } else {
                                                    s2 = peg$FAILED;
                                                  }
                                                  if (s2 !== peg$FAILED) {
                                                    s3 = peg$parsevector();
                                                    if (s3 === peg$FAILED) {
                                                      peg$savedPos = peg$currPos;
                                                      s3 = peg$c90();
                                                      if (s3) {
                                                        s3 = void 0;
                                                      } else {
                                                        s3 = peg$FAILED;
                                                      }
                                                    }
                                                    if (s3 !== peg$FAILED) {
                                                      peg$savedPos = s1;
                                                      s2 = peg$c67(s3);
                                                      s1 = s2;
                                                    } else {
                                                      peg$currPos = s1;
                                                      s1 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s1;
                                                    s1 = peg$FAILED;
                                                  }
                                                  if (s1 === peg$FAILED) {
                                                    s1 = peg$currPos;
                                                    peg$savedPos = peg$currPos;
                                                    s2 = peg$c91();
                                                    if (s2) {
                                                      s2 = void 0;
                                                    } else {
                                                      s2 = peg$FAILED;
                                                    }
                                                    if (s2 !== peg$FAILED) {
                                                      s3 = peg$parsevector();
                                                      if (s3 === peg$FAILED) {
                                                        s3 = null;
                                                      }
                                                      if (s3 !== peg$FAILED) {
                                                        peg$savedPos = s1;
                                                        s2 = peg$c67(s3);
                                                        s1 = s2;
                                                      } else {
                                                        peg$currPos = s1;
                                                        s1 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s1;
                                                      s1 = peg$FAILED;
                                                    }
                                                    if (s1 === peg$FAILED) {
                                                      s1 = peg$currPos;
                                                      peg$savedPos = peg$currPos;
                                                      s2 = peg$c92();
                                                      if (s2) {
                                                        s2 = void 0;
                                                      } else {
                                                        s2 = peg$FAILED;
                                                      }
                                                      if (s2 !== peg$FAILED) {
                                                        s3 = peg$parsecolumns();
                                                        if (s3 === peg$FAILED) {
                                                          peg$savedPos = peg$currPos;
                                                          s3 = peg$c93();
                                                          if (s3) {
                                                            s3 = void 0;
                                                          } else {
                                                            s3 = peg$FAILED;
                                                          }
                                                        }
                                                        if (s3 !== peg$FAILED) {
                                                          peg$savedPos = s1;
                                                          s2 = peg$c85(s3);
                                                          s1 = s2;
                                                        } else {
                                                          peg$currPos = s1;
                                                          s1 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s1;
                                                        s1 = peg$FAILED;
                                                      }
                                                      if (s1 === peg$FAILED) {
                                                        s1 = peg$currPos;
                                                        peg$savedPos = peg$currPos;
                                                        s2 = peg$c94();
                                                        if (s2) {
                                                          s2 = void 0;
                                                        } else {
                                                          s2 = peg$FAILED;
                                                        }
                                                        if (s2 !== peg$FAILED) {
                                                          s3 = peg$parseskip_space();
                                                          if (s3 !== peg$FAILED) {
                                                            s2 = [s2, s3];
                                                            s1 = s2;
                                                          } else {
                                                            peg$currPos = s1;
                                                            s1 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s1;
                                                          s1 = peg$FAILED;
                                                        }
                                                        if (s1 === peg$FAILED) {
                                                          s1 = peg$currPos;
                                                          peg$savedPos = peg$currPos;
                                                          s2 = peg$c95();
                                                          if (s2) {
                                                            s2 = void 0;
                                                          } else {
                                                            s2 = peg$FAILED;
                                                          }
                                                          if (s2 !== peg$FAILED) {
                                                            s3 = peg$parseitems();
                                                            if (s3 !== peg$FAILED) {
                                                              peg$savedPos = s1;
                                                              s2 = peg$c58(s3);
                                                              s1 = s2;
                                                            } else {
                                                              peg$currPos = s1;
                                                              s1 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s1;
                                                            s1 = peg$FAILED;
                                                          }
                                                          if (s1 === peg$FAILED) {
                                                            s1 = peg$currPos;
                                                            peg$savedPos = peg$currPos;
                                                            s2 = peg$c96();
                                                            if (s2) {
                                                              s2 = void 0;
                                                            } else {
                                                              s2 = peg$FAILED;
                                                            }
                                                            if (s2 !== peg$FAILED) {
                                                              s3 = peg$parseenumitems();
                                                              if (s3 !== peg$FAILED) {
                                                                peg$savedPos = s1;
                                                                s2 = peg$c58(s3);
                                                                s1 = s2;
                                                              } else {
                                                                peg$currPos = s1;
                                                                s1 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s1;
                                                              s1 = peg$FAILED;
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      while (s1 !== peg$FAILED) {
        s0.push(s1);
        s1 = peg$currPos;
        peg$savedPos = peg$currPos;
        s2 = peg$c45();
        if (s2) {
          s2 = void 0;
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s1;
          s2 = peg$c46();
        }
        s1 = s2;
        if (s1 === peg$FAILED) {
          s1 = peg$parsenextArgStar();
          if (s1 === peg$FAILED) {
            s1 = peg$currPos;
            peg$savedPos = peg$currPos;
            s2 = peg$c47();
            if (s2) {
              s2 = void 0;
            } else {
              s2 = peg$FAILED;
            }
            if (s2 !== peg$FAILED) {
              s3 = peg$parsearg_group();
              if (s3 === peg$FAILED) {
                peg$savedPos = peg$currPos;
                s3 = peg$c48();
                if (s3) {
                  s3 = void 0;
                } else {
                  s3 = peg$FAILED;
                }
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s1;
                s2 = peg$c49(s3);
                s1 = s2;
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
            if (s1 === peg$FAILED) {
              s1 = peg$currPos;
              peg$savedPos = peg$currPos;
              s2 = peg$c50();
              if (s2) {
                s2 = void 0;
              } else {
                s2 = peg$FAILED;
              }
              if (s2 !== peg$FAILED) {
                s3 = peg$parsearg_hgroup();
                if (s3 === peg$FAILED) {
                  peg$savedPos = peg$currPos;
                  s3 = peg$c48();
                  if (s3) {
                    s3 = void 0;
                  } else {
                    s3 = peg$FAILED;
                  }
                }
                if (s3 !== peg$FAILED) {
                  peg$savedPos = s1;
                  s2 = peg$c49(s3);
                  s1 = s2;
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
              if (s1 === peg$FAILED) {
                s1 = peg$currPos;
                peg$savedPos = peg$currPos;
                s2 = peg$c51();
                if (s2) {
                  s2 = void 0;
                } else {
                  s2 = peg$FAILED;
                }
                if (s2 !== peg$FAILED) {
                  s3 = peg$parsehorizontal();
                  if (s3 === peg$FAILED) {
                    peg$savedPos = peg$currPos;
                    s3 = peg$c52();
                    if (s3) {
                      s3 = void 0;
                    } else {
                      s3 = peg$FAILED;
                    }
                  }
                  if (s3 !== peg$FAILED) {
                    peg$savedPos = s1;
                    s2 = peg$c53(s3);
                    s1 = s2;
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
                if (s1 === peg$FAILED) {
                  s1 = peg$currPos;
                  peg$savedPos = peg$currPos;
                  s2 = peg$c54();
                  if (s2) {
                    s2 = void 0;
                  } else {
                    s2 = peg$FAILED;
                  }
                  if (s2 !== peg$FAILED) {
                    s3 = peg$parseopt_group();
                    if (s3 === peg$FAILED) {
                      s3 = null;
                    }
                    if (s3 !== peg$FAILED) {
                      peg$savedPos = s1;
                      s2 = peg$c55(s3);
                      s1 = s2;
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                  if (s1 === peg$FAILED) {
                    s1 = peg$currPos;
                    peg$savedPos = peg$currPos;
                    s2 = peg$c56();
                    if (s2) {
                      s2 = void 0;
                    } else {
                      s2 = peg$FAILED;
                    }
                    if (s2 !== peg$FAILED) {
                      s3 = peg$parseid_group();
                      if (s3 === peg$FAILED) {
                        peg$savedPos = peg$currPos;
                        s3 = peg$c57();
                        if (s3) {
                          s3 = void 0;
                        } else {
                          s3 = peg$FAILED;
                        }
                      }
                      if (s3 !== peg$FAILED) {
                        peg$savedPos = s1;
                        s2 = peg$c58(s3);
                        s1 = s2;
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                    if (s1 === peg$FAILED) {
                      s1 = peg$currPos;
                      peg$savedPos = peg$currPos;
                      s2 = peg$c59();
                      if (s2) {
                        s2 = void 0;
                      } else {
                        s2 = peg$FAILED;
                      }
                      if (s2 !== peg$FAILED) {
                        s3 = peg$parseid_optgroup();
                        if (s3 === peg$FAILED) {
                          s3 = null;
                        }
                        if (s3 !== peg$FAILED) {
                          peg$savedPos = s1;
                          s2 = peg$c58(s3);
                          s1 = s2;
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                      if (s1 === peg$FAILED) {
                        s1 = peg$currPos;
                        peg$savedPos = peg$currPos;
                        s2 = peg$c60();
                        if (s2) {
                          s2 = void 0;
                        } else {
                          s2 = peg$FAILED;
                        }
                        if (s2 !== peg$FAILED) {
                          s3 = peg$parsekey_group();
                          if (s3 === peg$FAILED) {
                            peg$savedPos = peg$currPos;
                            s3 = peg$c61();
                            if (s3) {
                              s3 = void 0;
                            } else {
                              s3 = peg$FAILED;
                            }
                          }
                          if (s3 !== peg$FAILED) {
                            peg$savedPos = s1;
                            s2 = peg$c62(s3);
                            s1 = s2;
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                        if (s1 === peg$FAILED) {
                          s1 = peg$currPos;
                          peg$savedPos = peg$currPos;
                          s2 = peg$c63();
                          if (s2) {
                            s2 = void 0;
                          } else {
                            s2 = peg$FAILED;
                          }
                          if (s2 !== peg$FAILED) {
                            s3 = peg$parsekey_optgroup();
                            if (s3 === peg$FAILED) {
                              s3 = null;
                            }
                            if (s3 !== peg$FAILED) {
                              peg$savedPos = s1;
                              s2 = peg$c62(s3);
                              s1 = s2;
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                          if (s1 === peg$FAILED) {
                            s1 = peg$currPos;
                            peg$savedPos = peg$currPos;
                            s2 = peg$c64();
                            if (s2) {
                              s2 = void 0;
                            } else {
                              s2 = peg$FAILED;
                            }
                            if (s2 !== peg$FAILED) {
                              s3 = peg$parsekeyval_optgroup();
                              if (s3 === peg$FAILED) {
                                s3 = null;
                              }
                              if (s3 !== peg$FAILED) {
                                peg$savedPos = s1;
                                s2 = peg$c62(s3);
                                s1 = s2;
                              } else {
                                peg$currPos = s1;
                                s1 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                            if (s1 === peg$FAILED) {
                              s1 = peg$currPos;
                              peg$savedPos = peg$currPos;
                              s2 = peg$c65();
                              if (s2) {
                                s2 = void 0;
                              } else {
                                s2 = peg$FAILED;
                              }
                              if (s2 !== peg$FAILED) {
                                s3 = peg$parsecsv_group();
                                if (s3 === peg$FAILED) {
                                  peg$savedPos = peg$currPos;
                                  s3 = peg$c66();
                                  if (s3) {
                                    s3 = void 0;
                                  } else {
                                    s3 = peg$FAILED;
                                  }
                                }
                                if (s3 !== peg$FAILED) {
                                  peg$savedPos = s1;
                                  s2 = peg$c67(s3);
                                  s1 = s2;
                                } else {
                                  peg$currPos = s1;
                                  s1 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s1;
                                s1 = peg$FAILED;
                              }
                              if (s1 === peg$FAILED) {
                                s1 = peg$currPos;
                                peg$savedPos = peg$currPos;
                                s2 = peg$c68();
                                if (s2) {
                                  s2 = void 0;
                                } else {
                                  s2 = peg$FAILED;
                                }
                                if (s2 !== peg$FAILED) {
                                  s3 = peg$parseexpr_group();
                                  if (s3 === peg$FAILED) {
                                    peg$savedPos = peg$currPos;
                                    s3 = peg$c69();
                                    if (s3) {
                                      s3 = void 0;
                                    } else {
                                      s3 = peg$FAILED;
                                    }
                                  }
                                  if (s3 !== peg$FAILED) {
                                    peg$savedPos = s1;
                                    s2 = peg$c70(s3);
                                    s1 = s2;
                                  } else {
                                    peg$currPos = s1;
                                    s1 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s1;
                                  s1 = peg$FAILED;
                                }
                                if (s1 === peg$FAILED) {
                                  s1 = peg$currPos;
                                  peg$savedPos = peg$currPos;
                                  s2 = peg$c71();
                                  if (s2) {
                                    s2 = void 0;
                                  } else {
                                    s2 = peg$FAILED;
                                  }
                                  if (s2 !== peg$FAILED) {
                                    s3 = peg$parseexpr_optgroup();
                                    if (s3 === peg$FAILED) {
                                      s3 = null;
                                    }
                                    if (s3 !== peg$FAILED) {
                                      peg$savedPos = s1;
                                      s2 = peg$c70(s3);
                                      s1 = s2;
                                    } else {
                                      peg$currPos = s1;
                                      s1 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s1;
                                    s1 = peg$FAILED;
                                  }
                                  if (s1 === peg$FAILED) {
                                    s1 = peg$currPos;
                                    peg$savedPos = peg$currPos;
                                    s2 = peg$c72();
                                    if (s2) {
                                      s2 = void 0;
                                    } else {
                                      s2 = peg$FAILED;
                                    }
                                    if (s2 !== peg$FAILED) {
                                      s3 = peg$parselength_group();
                                      if (s3 === peg$FAILED) {
                                        peg$savedPos = peg$currPos;
                                        s3 = peg$c73();
                                        if (s3) {
                                          s3 = void 0;
                                        } else {
                                          s3 = peg$FAILED;
                                        }
                                      }
                                      if (s3 !== peg$FAILED) {
                                        peg$savedPos = s1;
                                        s2 = peg$c74(s3);
                                        s1 = s2;
                                      } else {
                                        peg$currPos = s1;
                                        s1 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s1;
                                      s1 = peg$FAILED;
                                    }
                                    if (s1 === peg$FAILED) {
                                      s1 = peg$currPos;
                                      peg$savedPos = peg$currPos;
                                      s2 = peg$c75();
                                      if (s2) {
                                        s2 = void 0;
                                      } else {
                                        s2 = peg$FAILED;
                                      }
                                      if (s2 !== peg$FAILED) {
                                        s3 = peg$parselength_group();
                                        if (s3 === peg$FAILED) {
                                          s3 = null;
                                        }
                                        if (s3 !== peg$FAILED) {
                                          peg$savedPos = s1;
                                          s2 = peg$c74(s3);
                                          s1 = s2;
                                        } else {
                                          peg$currPos = s1;
                                          s1 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s1;
                                        s1 = peg$FAILED;
                                      }
                                      if (s1 === peg$FAILED) {
                                        s1 = peg$currPos;
                                        peg$savedPos = peg$currPos;
                                        s2 = peg$c76();
                                        if (s2) {
                                          s2 = void 0;
                                        } else {
                                          s2 = peg$FAILED;
                                        }
                                        if (s2 !== peg$FAILED) {
                                          s3 = peg$parselength_optgroup();
                                          if (s3 === peg$FAILED) {
                                            s3 = null;
                                          }
                                          if (s3 !== peg$FAILED) {
                                            peg$savedPos = s1;
                                            s2 = peg$c74(s3);
                                            s1 = s2;
                                          } else {
                                            peg$currPos = s1;
                                            s1 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s1;
                                          s1 = peg$FAILED;
                                        }
                                        if (s1 === peg$FAILED) {
                                          s1 = peg$currPos;
                                          peg$savedPos = peg$currPos;
                                          s2 = peg$c77();
                                          if (s2) {
                                            s2 = void 0;
                                          } else {
                                            s2 = peg$FAILED;
                                          }
                                          if (s2 !== peg$FAILED) {
                                            s3 = peg$parsemacro_group();
                                            if (s3 === peg$FAILED) {
                                              peg$savedPos = peg$currPos;
                                              s3 = peg$c78();
                                              if (s3) {
                                                s3 = void 0;
                                              } else {
                                                s3 = peg$FAILED;
                                              }
                                            }
                                            if (s3 !== peg$FAILED) {
                                              peg$savedPos = s1;
                                              s2 = peg$c79(s3);
                                              s1 = s2;
                                            } else {
                                              peg$currPos = s1;
                                              s1 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s1;
                                            s1 = peg$FAILED;
                                          }
                                          if (s1 === peg$FAILED) {
                                            s1 = peg$currPos;
                                            peg$savedPos = peg$currPos;
                                            s2 = peg$c80();
                                            if (s2) {
                                              s2 = void 0;
                                            } else {
                                              s2 = peg$FAILED;
                                            }
                                            if (s2 !== peg$FAILED) {
                                              s3 = peg$parseurl_group();
                                              if (s3 === peg$FAILED) {
                                                peg$savedPos = peg$currPos;
                                                s3 = peg$c81();
                                                if (s3) {
                                                  s3 = void 0;
                                                } else {
                                                  s3 = peg$FAILED;
                                                }
                                              }
                                              if (s3 !== peg$FAILED) {
                                                peg$savedPos = s1;
                                                s2 = peg$c82(s3);
                                                s1 = s2;
                                              } else {
                                                peg$currPos = s1;
                                                s1 = peg$FAILED;
                                              }
                                            } else {
                                              peg$currPos = s1;
                                              s1 = peg$FAILED;
                                            }
                                            if (s1 === peg$FAILED) {
                                              s1 = peg$currPos;
                                              peg$savedPos = peg$currPos;
                                              s2 = peg$c83();
                                              if (s2) {
                                                s2 = void 0;
                                              } else {
                                                s2 = peg$FAILED;
                                              }
                                              if (s2 !== peg$FAILED) {
                                                s3 = peg$parsecolor_group();
                                                if (s3 === peg$FAILED) {
                                                  peg$savedPos = peg$currPos;
                                                  s3 = peg$c84();
                                                  if (s3) {
                                                    s3 = void 0;
                                                  } else {
                                                    s3 = peg$FAILED;
                                                  }
                                                }
                                                if (s3 !== peg$FAILED) {
                                                  peg$savedPos = s1;
                                                  s2 = peg$c85(s3);
                                                  s1 = s2;
                                                } else {
                                                  peg$currPos = s1;
                                                  s1 = peg$FAILED;
                                                }
                                              } else {
                                                peg$currPos = s1;
                                                s1 = peg$FAILED;
                                              }
                                              if (s1 === peg$FAILED) {
                                                s1 = peg$currPos;
                                                peg$savedPos = peg$currPos;
                                                s2 = peg$c86();
                                                if (s2) {
                                                  s2 = void 0;
                                                } else {
                                                  s2 = peg$FAILED;
                                                }
                                                if (s2 !== peg$FAILED) {
                                                  s3 = peg$parsecoord_group();
                                                  if (s3 === peg$FAILED) {
                                                    peg$savedPos = peg$currPos;
                                                    s3 = peg$c87();
                                                    if (s3) {
                                                      s3 = void 0;
                                                    } else {
                                                      s3 = peg$FAILED;
                                                    }
                                                  }
                                                  if (s3 !== peg$FAILED) {
                                                    peg$savedPos = s1;
                                                    s2 = peg$c85(s3);
                                                    s1 = s2;
                                                  } else {
                                                    peg$currPos = s1;
                                                    s1 = peg$FAILED;
                                                  }
                                                } else {
                                                  peg$currPos = s1;
                                                  s1 = peg$FAILED;
                                                }
                                                if (s1 === peg$FAILED) {
                                                  s1 = peg$currPos;
                                                  peg$savedPos = peg$currPos;
                                                  s2 = peg$c88();
                                                  if (s2) {
                                                    s2 = void 0;
                                                  } else {
                                                    s2 = peg$FAILED;
                                                  }
                                                  if (s2 !== peg$FAILED) {
                                                    s3 = peg$parsecoord_optgroup();
                                                    if (s3 === peg$FAILED) {
                                                      s3 = null;
                                                    }
                                                    if (s3 !== peg$FAILED) {
                                                      peg$savedPos = s1;
                                                      s2 = peg$c85(s3);
                                                      s1 = s2;
                                                    } else {
                                                      peg$currPos = s1;
                                                      s1 = peg$FAILED;
                                                    }
                                                  } else {
                                                    peg$currPos = s1;
                                                    s1 = peg$FAILED;
                                                  }
                                                  if (s1 === peg$FAILED) {
                                                    s1 = peg$currPos;
                                                    peg$savedPos = peg$currPos;
                                                    s2 = peg$c89();
                                                    if (s2) {
                                                      s2 = void 0;
                                                    } else {
                                                      s2 = peg$FAILED;
                                                    }
                                                    if (s2 !== peg$FAILED) {
                                                      s3 = peg$parsevector();
                                                      if (s3 === peg$FAILED) {
                                                        peg$savedPos = peg$currPos;
                                                        s3 = peg$c90();
                                                        if (s3) {
                                                          s3 = void 0;
                                                        } else {
                                                          s3 = peg$FAILED;
                                                        }
                                                      }
                                                      if (s3 !== peg$FAILED) {
                                                        peg$savedPos = s1;
                                                        s2 = peg$c67(s3);
                                                        s1 = s2;
                                                      } else {
                                                        peg$currPos = s1;
                                                        s1 = peg$FAILED;
                                                      }
                                                    } else {
                                                      peg$currPos = s1;
                                                      s1 = peg$FAILED;
                                                    }
                                                    if (s1 === peg$FAILED) {
                                                      s1 = peg$currPos;
                                                      peg$savedPos = peg$currPos;
                                                      s2 = peg$c91();
                                                      if (s2) {
                                                        s2 = void 0;
                                                      } else {
                                                        s2 = peg$FAILED;
                                                      }
                                                      if (s2 !== peg$FAILED) {
                                                        s3 = peg$parsevector();
                                                        if (s3 === peg$FAILED) {
                                                          s3 = null;
                                                        }
                                                        if (s3 !== peg$FAILED) {
                                                          peg$savedPos = s1;
                                                          s2 = peg$c67(s3);
                                                          s1 = s2;
                                                        } else {
                                                          peg$currPos = s1;
                                                          s1 = peg$FAILED;
                                                        }
                                                      } else {
                                                        peg$currPos = s1;
                                                        s1 = peg$FAILED;
                                                      }
                                                      if (s1 === peg$FAILED) {
                                                        s1 = peg$currPos;
                                                        peg$savedPos = peg$currPos;
                                                        s2 = peg$c92();
                                                        if (s2) {
                                                          s2 = void 0;
                                                        } else {
                                                          s2 = peg$FAILED;
                                                        }
                                                        if (s2 !== peg$FAILED) {
                                                          s3 = peg$parsecolumns();
                                                          if (s3 === peg$FAILED) {
                                                            peg$savedPos = peg$currPos;
                                                            s3 = peg$c93();
                                                            if (s3) {
                                                              s3 = void 0;
                                                            } else {
                                                              s3 = peg$FAILED;
                                                            }
                                                          }
                                                          if (s3 !== peg$FAILED) {
                                                            peg$savedPos = s1;
                                                            s2 = peg$c85(s3);
                                                            s1 = s2;
                                                          } else {
                                                            peg$currPos = s1;
                                                            s1 = peg$FAILED;
                                                          }
                                                        } else {
                                                          peg$currPos = s1;
                                                          s1 = peg$FAILED;
                                                        }
                                                        if (s1 === peg$FAILED) {
                                                          s1 = peg$currPos;
                                                          peg$savedPos = peg$currPos;
                                                          s2 = peg$c94();
                                                          if (s2) {
                                                            s2 = void 0;
                                                          } else {
                                                            s2 = peg$FAILED;
                                                          }
                                                          if (s2 !== peg$FAILED) {
                                                            s3 = peg$parseskip_space();
                                                            if (s3 !== peg$FAILED) {
                                                              s2 = [s2, s3];
                                                              s1 = s2;
                                                            } else {
                                                              peg$currPos = s1;
                                                              s1 = peg$FAILED;
                                                            }
                                                          } else {
                                                            peg$currPos = s1;
                                                            s1 = peg$FAILED;
                                                          }
                                                          if (s1 === peg$FAILED) {
                                                            s1 = peg$currPos;
                                                            peg$savedPos = peg$currPos;
                                                            s2 = peg$c95();
                                                            if (s2) {
                                                              s2 = void 0;
                                                            } else {
                                                              s2 = peg$FAILED;
                                                            }
                                                            if (s2 !== peg$FAILED) {
                                                              s3 = peg$parseitems();
                                                              if (s3 !== peg$FAILED) {
                                                                peg$savedPos = s1;
                                                                s2 = peg$c58(s3);
                                                                s1 = s2;
                                                              } else {
                                                                peg$currPos = s1;
                                                                s1 = peg$FAILED;
                                                              }
                                                            } else {
                                                              peg$currPos = s1;
                                                              s1 = peg$FAILED;
                                                            }
                                                            if (s1 === peg$FAILED) {
                                                              s1 = peg$currPos;
                                                              peg$savedPos = peg$currPos;
                                                              s2 = peg$c96();
                                                              if (s2) {
                                                                s2 = void 0;
                                                              } else {
                                                                s2 = peg$FAILED;
                                                              }
                                                              if (s2 !== peg$FAILED) {
                                                                s3 = peg$parseenumitems();
                                                                if (s3 !== peg$FAILED) {
                                                                  peg$savedPos = s1;
                                                                  s2 = peg$c58(s3);
                                                                  s1 = s2;
                                                                } else {
                                                                  peg$currPos = s1;
                                                                  s1 = peg$FAILED;
                                                                }
                                                              } else {
                                                                peg$currPos = s1;
                                                                s1 = peg$FAILED;
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parsenextArgStar() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      peg$savedPos = peg$currPos;
      s1 = peg$c97();
      if (s1) {
        s1 = void 0;
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parseskip_space();
        if (s2 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 42) {
            s3 = peg$c98;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c99); }
          }
          if (s3 === peg$FAILED) {
            s3 = null;
          }
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c100(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseid_group() {
      var s0, s1, s2, s3, s4, s5, s6;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_group();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s4 = peg$parseidentifier();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseskip_space();
              if (s5 !== peg$FAILED) {
                s6 = peg$parseend_group();
                if (s6 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c101(s4);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsemacro_group() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_group();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s4 = peg$parseescape();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseidentifier();
              if (s5 !== peg$FAILED) {
                s6 = peg$parseskip_space();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseend_group();
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c101(s5);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseid_optgroup() {
      var s0, s1, s2, s3, s4, s5, s6;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_optgroup();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s4 = peg$parseidentifier();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseskip_space();
              if (s5 !== peg$FAILED) {
                s6 = peg$parseend_optgroup();
                if (s6 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c101(s4);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsekey_group() {
      var s0, s1, s2, s3, s4, s5, s6;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_group();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s4 = peg$parsekey();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseskip_space();
              if (s5 !== peg$FAILED) {
                s6 = peg$parseend_group();
                if (s6 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c102(s4);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsekey_optgroup() {
      var s0, s1, s2, s3, s4, s5, s6;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_optgroup();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s4 = peg$parsekey();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseskip_space();
              if (s5 !== peg$FAILED) {
                s6 = peg$parseend_optgroup();
                if (s6 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c102(s4);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsekeyval_optgroup() {
      var s0, s1, s2, s3, s4, s5, s6;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_optgroup();
        if (s2 !== peg$FAILED) {
          s3 = [];
          s4 = peg$currPos;
          s5 = peg$parseskip_space();
          if (s5 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 44) {
              s6 = peg$c103;
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c104); }
            }
            if (s6 !== peg$FAILED) {
              peg$savedPos = s4;
              s5 = peg$c105();
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 === peg$FAILED) {
            s4 = peg$currPos;
            s5 = peg$parseskip_space();
            if (s5 !== peg$FAILED) {
              s6 = peg$parsekey_val();
              if (s6 !== peg$FAILED) {
                peg$savedPos = s4;
                s5 = peg$c106(s6);
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$currPos;
            s5 = peg$parseskip_space();
            if (s5 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 44) {
                s6 = peg$c103;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c104); }
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s4;
                s5 = peg$c105();
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
            if (s4 === peg$FAILED) {
              s4 = peg$currPos;
              s5 = peg$parseskip_space();
              if (s5 !== peg$FAILED) {
                s6 = peg$parsekey_val();
                if (s6 !== peg$FAILED) {
                  peg$savedPos = s4;
                  s5 = peg$c106(s6);
                  s4 = s5;
                } else {
                  peg$currPos = s4;
                  s4 = peg$FAILED;
                }
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseskip_space();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseend_optgroup();
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c107(s3);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsecsv_group() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_group();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s4 = [];
            s5 = peg$currPos;
            s6 = peg$parseskip_space();
            if (s6 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 44) {
                s7 = peg$c103;
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c104); }
              }
              if (s7 !== peg$FAILED) {
                peg$savedPos = s5;
                s6 = peg$c105();
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 === peg$FAILED) {
              s5 = peg$currPos;
              s6 = peg$parseskip_space();
              if (s6 !== peg$FAILED) {
                s7 = peg$parsekey();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s5;
                  s6 = peg$c108(s7);
                  s5 = s6;
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            }
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$currPos;
              s6 = peg$parseskip_space();
              if (s6 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 44) {
                  s7 = peg$c103;
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c104); }
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s5;
                  s6 = peg$c105();
                  s5 = s6;
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
              if (s5 === peg$FAILED) {
                s5 = peg$currPos;
                s6 = peg$parseskip_space();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parsekey();
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s5;
                    s6 = peg$c108(s7);
                    s5 = s6;
                  } else {
                    peg$currPos = s5;
                    s5 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              }
            }
            if (s4 !== peg$FAILED) {
              s5 = peg$parseskip_space();
              if (s5 !== peg$FAILED) {
                s6 = peg$parseend_group();
                if (s6 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c109(s4);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parselength_unit() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c110) {
          s2 = peg$c110;
          peg$currPos += 2;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c111); }
        }
        if (s2 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c112) {
            s2 = peg$c112;
            peg$currPos += 2;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c113); }
          }
          if (s2 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c114) {
              s2 = peg$c114;
              peg$currPos += 2;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c115); }
            }
            if (s2 === peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c116) {
                s2 = peg$c116;
                peg$currPos += 2;
              } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c117); }
              }
              if (s2 === peg$FAILED) {
                if (input.substr(peg$currPos, 2) === peg$c118) {
                  s2 = peg$c118;
                  peg$currPos += 2;
                } else {
                  s2 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c119); }
                }
                if (s2 === peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c120) {
                    s2 = peg$c120;
                    peg$currPos += 2;
                  } else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c121); }
                  }
                  if (s2 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c122) {
                      s2 = peg$c122;
                      peg$currPos += 2;
                    } else {
                      s2 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c123); }
                    }
                    if (s2 === peg$FAILED) {
                      if (input.substr(peg$currPos, 2) === peg$c124) {
                        s2 = peg$c124;
                        peg$currPos += 2;
                      } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c125); }
                      }
                      if (s2 === peg$FAILED) {
                        if (input.substr(peg$currPos, 2) === peg$c126) {
                          s2 = peg$c126;
                          peg$currPos += 2;
                        } else {
                          s2 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c127); }
                        }
                        if (s2 === peg$FAILED) {
                          if (input.substr(peg$currPos, 2) === peg$c128) {
                            s2 = peg$c128;
                            peg$currPos += 2;
                          } else {
                            s2 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c129); }
                          }
                          if (s2 === peg$FAILED) {
                            if (input.substr(peg$currPos, 2) === peg$c130) {
                              s2 = peg$c130;
                              peg$currPos += 2;
                            } else {
                              s2 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c131); }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          peg$silentFails++;
          s4 = peg$parsechar();
          peg$silentFails--;
          if (s4 === peg$FAILED) {
            s3 = void 0;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseskip_space();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c132(s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parselength() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      s0 = peg$currPos;
      s1 = peg$parsefloat();
      if (s1 !== peg$FAILED) {
        s2 = peg$parselength_unit();
        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$parseplus();
          if (s4 !== peg$FAILED) {
            s5 = peg$parsefloat();
            if (s5 !== peg$FAILED) {
              s6 = peg$parselength_unit();
              if (s6 !== peg$FAILED) {
                s4 = [s4, s5, s6];
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
          if (s3 === peg$FAILED) {
            s3 = null;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$currPos;
            s5 = peg$parseminus();
            if (s5 !== peg$FAILED) {
              s6 = peg$parsefloat();
              if (s6 !== peg$FAILED) {
                s7 = peg$parselength_unit();
                if (s7 !== peg$FAILED) {
                  s5 = [s5, s6, s7];
                  s4 = s5;
                } else {
                  peg$currPos = s4;
                  s4 = peg$FAILED;
                }
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
            if (s4 === peg$FAILED) {
              s4 = null;
            }
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c133(s1, s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parselength_group() {
      var s0, s1, s2, s3, s4, s5;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_group();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s4 = peg$parselength();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseend_group();
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c134(s4);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parselength_optgroup() {
      var s0, s1, s2, s3, s4, s5;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_optgroup();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s4 = peg$parselength();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseend_optgroup();
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c134(s4);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseexpr_group() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_group();
        if (s2 !== peg$FAILED) {
          s3 = peg$parsenum_expr();
          if (s3 !== peg$FAILED) {
            s4 = peg$parseend_group();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c135(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseexpr_optgroup() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_optgroup();
        if (s2 !== peg$FAILED) {
          s3 = peg$parsenum_expr();
          if (s3 !== peg$FAILED) {
            s4 = peg$parseend_optgroup();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c135(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsefloat_group() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_group();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseend_group();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c136();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsecolor_group() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_group();
        if (s2 !== peg$FAILED) {
          s3 = peg$parsecolor();
          if (s3 !== peg$FAILED) {
            s4 = peg$parseend_group();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c137(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsecoordinate() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parselength();
        if (s2 === peg$FAILED) {
          s2 = peg$currPos;
          s3 = peg$parsefloat();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s2;
            s3 = peg$c138(s3);
          }
          s2 = s3;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c137(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsevector() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 40) {
          s2 = peg$c139;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c140); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parsecoordinate();
          if (s3 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 44) {
              s4 = peg$c103;
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c104); }
            }
            if (s4 !== peg$FAILED) {
              s5 = peg$parsecoordinate();
              if (s5 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 41) {
                  s6 = peg$c141;
                  peg$currPos++;
                } else {
                  s6 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c142); }
                }
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseskip_space();
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c143(s3, s5);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsecoord_group() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_group();
        if (s2 !== peg$FAILED) {
          s3 = peg$parsecoordinate();
          if (s3 !== peg$FAILED) {
            s4 = peg$parseend_group();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c137(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsecoord_optgroup() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_optgroup();
        if (s2 !== peg$FAILED) {
          s3 = peg$parsecoordinate();
          if (s3 !== peg$FAILED) {
            s4 = peg$parseend_optgroup();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c137(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseurl_pct_encoded() {
      var s0, s1, s2, s3, s4, s5, s6;

      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 === peg$FAILED) {
        s1 = null;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 37) {
          s4 = peg$c144;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c145); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parsehex();
          if (s5 !== peg$FAILED) {
            s6 = peg$parsehex();
            if (s6 !== peg$FAILED) {
              s4 = [s4, s5, s6];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s2 = input.substring(s2, peg$currPos);
        } else {
          s2 = s3;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c146(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseurl_char() {
      var s0;

      s0 = peg$parsechar();
      if (s0 === peg$FAILED) {
        s0 = peg$parsedigit();
        if (s0 === peg$FAILED) {
          if (peg$c147.test(input.charAt(peg$currPos))) {
            s0 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c148); }
          }
          if (s0 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 39) {
              s0 = peg$c149;
              peg$currPos++;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c150); }
            }
            if (s0 === peg$FAILED) {
              s0 = peg$parseurl_pct_encoded();
              if (s0 === peg$FAILED) {
                peg$savedPos = peg$currPos;
                s0 = peg$c151();
                if (s0) {
                  s0 = void 0;
                } else {
                  s0 = peg$FAILED;
                }
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parseurl_group() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_group();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s4 = [];
            s5 = peg$currPos;
            s6 = peg$currPos;
            peg$silentFails++;
            s7 = peg$currPos;
            s8 = peg$parseskip_space();
            if (s8 !== peg$FAILED) {
              s9 = peg$parseend_group();
              if (s9 !== peg$FAILED) {
                s8 = [s8, s9];
                s7 = s8;
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
            } else {
              peg$currPos = s7;
              s7 = peg$FAILED;
            }
            peg$silentFails--;
            if (s7 === peg$FAILED) {
              s6 = void 0;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parseurl_char();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s5;
                s6 = peg$c152(s7);
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              while (s5 !== peg$FAILED) {
                s4.push(s5);
                s5 = peg$currPos;
                s6 = peg$currPos;
                peg$silentFails++;
                s7 = peg$currPos;
                s8 = peg$parseskip_space();
                if (s8 !== peg$FAILED) {
                  s9 = peg$parseend_group();
                  if (s9 !== peg$FAILED) {
                    s8 = [s8, s9];
                    s7 = s8;
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
                peg$silentFails--;
                if (s7 === peg$FAILED) {
                  s6 = void 0;
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseurl_char();
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s5;
                    s6 = peg$c152(s7);
                    s5 = s6;
                  } else {
                    peg$currPos = s5;
                    s5 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              }
            } else {
              s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
              s5 = peg$parseskip_space();
              if (s5 !== peg$FAILED) {
                s6 = peg$parseend_group();
                if (s6 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c153(s4);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsearg_group() {
      var s0, s1, s2, s3, s4, s5, s6;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_group();
        if (s2 !== peg$FAILED) {
          peg$savedPos = peg$currPos;
          s3 = peg$c154();
          if (s3) {
            s3 = void 0;
          } else {
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parsespace();
            if (s4 === peg$FAILED) {
              s4 = null;
            }
            if (s4 !== peg$FAILED) {
              s5 = [];
              s6 = peg$parseparagraph_with_linebreak();
              while (s6 !== peg$FAILED) {
                s5.push(s6);
                s6 = peg$parseparagraph_with_linebreak();
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parseend_group();
                if (s6 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c155(s4, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsehorizontal() {
      var s0, s1, s2;

      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parseline();
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parseline();
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c156(s1);
      }
      s0 = s1;

      return s0;
    }

    function peg$parsearg_hgroup() {
      var s0, s1, s2, s3, s4, s5, s6;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_group();
        if (s2 !== peg$FAILED) {
          peg$savedPos = peg$currPos;
          s3 = peg$c154();
          if (s3) {
            s3 = void 0;
          } else {
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parsespace();
            if (s4 === peg$FAILED) {
              s4 = null;
            }
            if (s4 !== peg$FAILED) {
              s5 = peg$parsehorizontal();
              if (s5 !== peg$FAILED) {
                s6 = peg$parseend_group();
                if (s6 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c157(s4, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseopt_group() {
      var s0, s1, s2, s3, s4, s5, s6;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_optgroup();
        if (s2 !== peg$FAILED) {
          peg$savedPos = peg$currPos;
          s3 = peg$c154();
          if (s3) {
            s3 = void 0;
          } else {
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s4 = [];
            s5 = peg$parseparagraph_with_linebreak();
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$parseparagraph_with_linebreak();
            }
            if (s4 !== peg$FAILED) {
              s5 = peg$parseend_optgroup();
              if (s5 !== peg$FAILED) {
                peg$savedPos = peg$currPos;
                s6 = peg$c158(s4);
                if (s6) {
                  s6 = void 0;
                } else {
                  s6 = peg$FAILED;
                }
                if (s6 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c159(s4);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsevalue() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c160) {
          s2 = peg$c160;
          peg$currPos += 5;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c161); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseid_group();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c137(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsereal() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8;

      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c162) {
          s2 = peg$c162;
          peg$currPos += 4;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c163); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s4 = peg$parsebegin_group();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseskip_space();
              if (s5 !== peg$FAILED) {
                s6 = peg$parsefloat();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseskip_space();
                  if (s7 !== peg$FAILED) {
                    s8 = peg$parseend_group();
                    if (s8 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c164(s6);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsenum_value() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 40) {
        s1 = peg$c139;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c140); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parsenum_expr();
        if (s2 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 41) {
            s3 = peg$c141;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c142); }
          }
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c165(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$parseinteger();
        if (s0 === peg$FAILED) {
          s0 = peg$parsereal();
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parsevalue();
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c166(s1);
            }
            s0 = s1;
          }
        }
      }

      return s0;
    }

    function peg$parsenum_factor() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 43) {
        s1 = peg$c167;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c168); }
      }
      if (s1 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 45) {
          s1 = peg$c169;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c170); }
        }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parseskip_space();
        if (s2 !== peg$FAILED) {
          s3 = peg$parsenum_factor();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c171(s1, s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$parsenum_value();
      }

      return s0;
    }

    function peg$parsenum_term() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      s0 = peg$currPos;
      s1 = peg$parsenum_factor();
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$parseskip_space();
        if (s4 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 42) {
            s5 = peg$c98;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c99); }
          }
          if (s5 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 47) {
              s5 = peg$c172;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c173); }
            }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parseskip_space();
            if (s6 !== peg$FAILED) {
              s7 = peg$parsenum_factor();
              if (s7 !== peg$FAILED) {
                s4 = [s4, s5, s6, s7];
                s3 = s4;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parseskip_space();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 42) {
              s5 = peg$c98;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c99); }
            }
            if (s5 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 47) {
                s5 = peg$c172;
                peg$currPos++;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c173); }
              }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parseskip_space();
              if (s6 !== peg$FAILED) {
                s7 = peg$parsenum_factor();
                if (s7 !== peg$FAILED) {
                  s4 = [s4, s5, s6, s7];
                  s3 = s4;
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c174(s1, s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsenum_expr() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsenum_term();
        if (s2 !== peg$FAILED) {
          s3 = [];
          s4 = peg$currPos;
          s5 = peg$parseskip_space();
          if (s5 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 43) {
              s6 = peg$c167;
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c168); }
            }
            if (s6 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 45) {
                s6 = peg$c169;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c170); }
              }
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parseskip_space();
              if (s7 !== peg$FAILED) {
                s8 = peg$parsenum_term();
                if (s8 !== peg$FAILED) {
                  s5 = [s5, s6, s7, s8];
                  s4 = s5;
                } else {
                  peg$currPos = s4;
                  s4 = peg$FAILED;
                }
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$currPos;
            s5 = peg$parseskip_space();
            if (s5 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 43) {
                s6 = peg$c167;
                peg$currPos++;
              } else {
                s6 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c168); }
              }
              if (s6 === peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 45) {
                  s6 = peg$c169;
                  peg$currPos++;
                } else {
                  s6 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c170); }
                }
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseskip_space();
                if (s7 !== peg$FAILED) {
                  s8 = peg$parsenum_term();
                  if (s8 !== peg$FAILED) {
                    s5 = [s5, s6, s7, s8];
                    s4 = s5;
                  } else {
                    peg$currPos = s4;
                    s4 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s4;
                  s4 = peg$FAILED;
                }
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseskip_space();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c175(s2, s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsecolor() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      s1 = peg$parsec_name();
      if (s1 === peg$FAILED) {
        s1 = peg$parsec_ext_expr();
        if (s1 === peg$FAILED) {
          s1 = peg$parsec_expr();
        }
      }
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parsefunc_expr();
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parsefunc_expr();
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsec_ext_expr() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

      s0 = peg$currPos;
      s1 = peg$parsecore_model();
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 44) {
          s2 = peg$c103;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c104); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseint();
          if (s3 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 58) {
              s4 = peg$c176;
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c177); }
            }
            if (s4 !== peg$FAILED) {
              s5 = [];
              s6 = peg$currPos;
              s7 = peg$parsec_expr();
              if (s7 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 44) {
                  s8 = peg$c103;
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c104); }
                }
                if (s8 !== peg$FAILED) {
                  s9 = peg$parsefloat();
                  if (s9 !== peg$FAILED) {
                    s7 = [s7, s8, s9];
                    s6 = s7;
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s6;
                  s6 = peg$FAILED;
                }
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
              if (s6 !== peg$FAILED) {
                while (s6 !== peg$FAILED) {
                  s5.push(s6);
                  s6 = peg$currPos;
                  s7 = peg$parsec_expr();
                  if (s7 !== peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 44) {
                      s8 = peg$c103;
                      peg$currPos++;
                    } else {
                      s8 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c104); }
                    }
                    if (s8 !== peg$FAILED) {
                      s9 = peg$parsefloat();
                      if (s9 !== peg$FAILED) {
                        s7 = [s7, s8, s9];
                        s6 = s7;
                      } else {
                        peg$currPos = s6;
                        s6 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s6;
                      s6 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s6;
                    s6 = peg$FAILED;
                  }
                }
              } else {
                s5 = peg$FAILED;
              }
              if (s5 !== peg$FAILED) {
                s1 = [s1, s2, s3, s4, s5];
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsec_expr() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = peg$parsec_prefix();
      if (s1 === peg$FAILED) {
        s1 = null;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parsec_name();
        if (s2 !== peg$FAILED) {
          s3 = peg$parsec_mix_expr();
          if (s3 !== peg$FAILED) {
            s4 = peg$parsec_postfix();
            if (s4 === peg$FAILED) {
              s4 = null;
            }
            if (s4 !== peg$FAILED) {
              s1 = [s1, s2, s3, s4];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsec_mix_expr() {
      var s0, s1, s2, s3, s4, s5, s6;

      s0 = peg$currPos;
      s1 = [];
      s2 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 33) {
        s3 = peg$c178;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c179); }
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parsefloat();
        if (s4 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 33) {
            s5 = peg$c178;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c179); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parsec_name();
            if (s6 !== peg$FAILED) {
              s3 = [s3, s4, s5, s6];
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 33) {
          s3 = peg$c178;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c179); }
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsefloat();
          if (s4 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 33) {
              s5 = peg$c178;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c179); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parsec_name();
              if (s6 !== peg$FAILED) {
                s3 = [s3, s4, s5, s6];
                s2 = s3;
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 33) {
          s2 = peg$c178;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c179); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parsefloat();
          if (s3 !== peg$FAILED) {
            s4 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 33) {
              s5 = peg$c178;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c179); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parsec_name();
              if (s6 !== peg$FAILED) {
                s5 = [s5, s6];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
            if (s4 === peg$FAILED) {
              s4 = null;
            }
            if (s4 !== peg$FAILED) {
              s1 = [s1, s2, s3, s4];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsefunc_expr() {
      var s0, s1, s2, s3, s4, s5;

      s0 = peg$currPos;
      s1 = peg$parsefn();
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 44) {
          s4 = peg$c103;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c104); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parsefloat();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 44) {
            s4 = peg$c103;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c104); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parsefloat();
            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsefn() {
      var s0, s1, s2;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 62) {
        s1 = peg$c180;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c181); }
      }
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c182) {
          s2 = peg$c182;
          peg$currPos += 5;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c183); }
        }
        if (s2 === peg$FAILED) {
          if (input.substr(peg$currPos, 6) === peg$c184) {
            s2 = peg$c184;
            peg$currPos += 6;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c185); }
          }
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsec_prefix() {
      var s0, s1, s2;

      s0 = peg$currPos;
      s1 = [];
      if (input.charCodeAt(peg$currPos) === 45) {
        s2 = peg$c169;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c170); }
      }
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        if (input.charCodeAt(peg$currPos) === 45) {
          s2 = peg$c169;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c170); }
        }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c186(s1);
      }
      s0 = s1;

      return s0;
    }

    function peg$parsec_name() {
      var s0, s1, s2;

      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parsechar();
      if (s2 === peg$FAILED) {
        s2 = peg$parsedigit();
        if (s2 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 46) {
            s2 = peg$c187;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c188); }
          }
        }
      }
      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$parsechar();
          if (s2 === peg$FAILED) {
            s2 = peg$parsedigit();
            if (s2 === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 46) {
                s2 = peg$c187;
                peg$currPos++;
              } else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c188); }
              }
            }
          }
        }
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s0 = input.substring(s0, peg$currPos);
      } else {
        s0 = s1;
      }

      return s0;
    }

    function peg$parsec_postfix() {
      var s0, s1, s2, s3, s4, s5;

      s0 = peg$currPos;
      if (input.substr(peg$currPos, 2) === peg$c189) {
        s1 = peg$c189;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c190); }
      }
      if (s1 !== peg$FAILED) {
        s2 = [];
        if (input.charCodeAt(peg$currPos) === 43) {
          s3 = peg$c167;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c168); }
        }
        if (s3 !== peg$FAILED) {
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            if (input.charCodeAt(peg$currPos) === 43) {
              s3 = peg$c167;
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c168); }
            }
          }
        } else {
          s2 = peg$FAILED;
        }
        if (s2 === peg$FAILED) {
          s2 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 91) {
            s3 = peg$c191;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c192); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseint();
            if (s4 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 93) {
                s5 = peg$c193;
                peg$currPos++;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c194); }
              }
              if (s5 !== peg$FAILED) {
                s3 = [s3, s4, s5];
                s2 = s3;
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsecolor_model() {
      var s0;

      s0 = peg$parsecore_model();
      if (s0 === peg$FAILED) {
        s0 = peg$parseint_model();
        if (s0 === peg$FAILED) {
          s0 = peg$parsedec_model();
          if (s0 === peg$FAILED) {
            s0 = peg$parsepseudo_model();
          }
        }
      }

      return s0;
    }

    function peg$parsecore_model() {
      var s0;

      if (input.substr(peg$currPos, 3) === peg$c195) {
        s0 = peg$c195;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c196); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c197) {
          s0 = peg$c197;
          peg$currPos += 3;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c198); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c199) {
            s0 = peg$c199;
            peg$currPos += 4;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c200); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 3) === peg$c201) {
              s0 = peg$c201;
              peg$currPos += 3;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c202); }
            }
            if (s0 === peg$FAILED) {
              if (input.substr(peg$currPos, 4) === peg$c203) {
                s0 = peg$c203;
                peg$currPos += 4;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c204); }
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parseint_model() {
      var s0;

      if (input.substr(peg$currPos, 3) === peg$c205) {
        s0 = peg$c205;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c206); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c207) {
          s0 = peg$c207;
          peg$currPos += 4;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c208); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c209) {
            s0 = peg$c209;
            peg$currPos += 3;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c210); }
          }
          if (s0 === peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c211) {
              s0 = peg$c211;
              peg$currPos += 4;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c212); }
            }
          }
        }
      }

      return s0;
    }

    function peg$parsedec_model() {
      var s0;

      if (input.substr(peg$currPos, 3) === peg$c213) {
        s0 = peg$c213;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c214); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c215) {
          s0 = peg$c215;
          peg$currPos += 4;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c216); }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c217) {
            s0 = peg$c217;
            peg$currPos += 4;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c218); }
          }
        }
      }

      return s0;
    }

    function peg$parsepseudo_model() {
      var s0;

      if (input.substr(peg$currPos, 5) === peg$c219) {
        s0 = peg$c219;
        peg$currPos += 5;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c220); }
      }

      return s0;
    }

    function peg$parsec_type() {
      var s0;

      if (input.substr(peg$currPos, 5) === peg$c219) {
        s0 = peg$c219;
        peg$currPos += 5;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c220); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c221) {
          s0 = peg$c221;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c222); }
        }
      }

      return s0;
    }

    function peg$parsecolor_spec() {
      var s0, s1, s2, s3, s4, s5;

      s0 = peg$currPos;
      s1 = peg$parsefloat();
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$parsesp();
        if (s4 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 44) {
            s4 = peg$c103;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c104); }
          }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parsefloat();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parsesp();
          if (s4 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 44) {
              s4 = peg$c103;
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c104); }
            }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parsefloat();
            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$parsec_name();
      }

      return s0;
    }

    function peg$parsecolor_spec_list() {
      var s0, s1, s2, s3, s4, s5;

      s0 = peg$currPos;
      s1 = peg$parsecolor_spec();
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 47) {
          s4 = peg$c172;
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c173); }
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parsecolor_spec();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 47) {
            s4 = peg$c172;
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c173); }
          }
          if (s4 !== peg$FAILED) {
            s5 = peg$parsecolor_spec();
            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        if (s2 !== peg$FAILED) {
          s1 = [s1, s2];
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsemodel_list() {
      var s0, s1, s2, s3, s4, s5, s6;

      s0 = peg$currPos;
      s1 = peg$currPos;
      s2 = peg$parsecore_model();
      if (s2 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 58) {
          s3 = peg$c176;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c177); }
        }
        if (s3 !== peg$FAILED) {
          s2 = [s2, s3];
          s1 = s2;
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
      if (s1 === peg$FAILED) {
        s1 = null;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parsecolor_model();
        if (s2 !== peg$FAILED) {
          s3 = [];
          s4 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 47) {
            s5 = peg$c172;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c173); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parsecolor_model();
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 47) {
              s5 = peg$c172;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c173); }
            }
            if (s5 !== peg$FAILED) {
              s6 = peg$parsecolor_model();
              if (s6 !== peg$FAILED) {
                s5 = [s5, s6];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          }
          if (s3 !== peg$FAILED) {
            s1 = [s1, s2, s3];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsecolumns() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      s0 = peg$currPos;
      s1 = peg$parsebegin_group();
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parsecolumn_separator();
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parsecolumn_separator();
        }
        if (s2 !== peg$FAILED) {
          s3 = [];
          s4 = peg$currPos;
          s5 = peg$parsecolumn();
          if (s5 !== peg$FAILED) {
            s6 = [];
            s7 = peg$parsecolumn_separator();
            while (s7 !== peg$FAILED) {
              s6.push(s7);
              s7 = peg$parsecolumn_separator();
            }
            if (s6 !== peg$FAILED) {
              peg$savedPos = s4;
              s5 = peg$c223(s2, s5, s6);
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            while (s4 !== peg$FAILED) {
              s3.push(s4);
              s4 = peg$currPos;
              s5 = peg$parsecolumn();
              if (s5 !== peg$FAILED) {
                s6 = [];
                s7 = peg$parsecolumn_separator();
                while (s7 !== peg$FAILED) {
                  s6.push(s7);
                  s7 = peg$parsecolumn_separator();
                }
                if (s6 !== peg$FAILED) {
                  peg$savedPos = s4;
                  s5 = peg$c223(s2, s5, s6);
                  s4 = s5;
                } else {
                  peg$currPos = s4;
                  s4 = peg$FAILED;
                }
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            }
          } else {
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseend_group();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c224(s2, s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsecolumn() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 108) {
        s1 = peg$c225;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c226); }
      }
      if (s1 === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 99) {
          s1 = peg$c227;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c228); }
        }
        if (s1 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 114) {
            s1 = peg$c229;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c230); }
          }
          if (s1 === peg$FAILED) {
            s1 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 112) {
              s2 = peg$c231;
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c232); }
            }
            if (s2 !== peg$FAILED) {
              s3 = peg$parselength_group();
              if (s3 !== peg$FAILED) {
                peg$savedPos = s1;
                s2 = peg$c134(s3);
                s1 = s2;
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          }
        }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c233(s1);
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 42) {
          s1 = peg$c98;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c99); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parseexpr_group();
          if (s2 !== peg$FAILED) {
            s3 = peg$parsecolumns();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c234(s2, s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }

      return s0;
    }

    function peg$parsecolumn_separator() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 124) {
        s1 = peg$c235;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c236); }
      }
      if (s1 === peg$FAILED) {
        s1 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 64) {
          s2 = peg$c237;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c238); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parsearg_group();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s1;
            s2 = peg$c239(s3);
            s1 = s2;
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c240(s1);
      }
      s0 = s1;

      return s0;
    }

    function peg$parsevspace_hmode() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      if (input.substr(peg$currPos, 6) === peg$c241) {
        s1 = peg$c241;
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c242); }
      }
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 42) {
          s2 = peg$c98;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c99); }
        }
        if (s2 === peg$FAILED) {
          s2 = null;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parselength_group();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c243(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsevspace_vmode() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      if (input.substr(peg$currPos, 6) === peg$c241) {
        s1 = peg$c241;
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c242); }
      }
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 42) {
          s2 = peg$c98;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c99); }
        }
        if (s2 === peg$FAILED) {
          s2 = null;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parselength_group();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c244(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsesmbskip_hmode() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = peg$currPos;
      if (input.substr(peg$currPos, 5) === peg$c245) {
        s2 = peg$c245;
        peg$currPos += 5;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c246); }
      }
      if (s2 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c247) {
          s2 = peg$c247;
          peg$currPos += 3;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c248); }
        }
        if (s2 === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c249) {
            s2 = peg$c249;
            peg$currPos += 3;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c250); }
          }
        }
      }
      if (s2 !== peg$FAILED) {
        s1 = input.substring(s1, peg$currPos);
      } else {
        s1 = s2;
      }
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c251) {
          s2 = peg$c251;
          peg$currPos += 4;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c252); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          peg$silentFails++;
          s4 = peg$parsechar();
          peg$silentFails--;
          if (s4 === peg$FAILED) {
            s3 = void 0;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseskip_space();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c253(s1);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsesmbskip_vmode() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = peg$currPos;
      if (input.substr(peg$currPos, 5) === peg$c245) {
        s2 = peg$c245;
        peg$currPos += 5;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c246); }
      }
      if (s2 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c247) {
          s2 = peg$c247;
          peg$currPos += 3;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c248); }
        }
        if (s2 === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c249) {
            s2 = peg$c249;
            peg$currPos += 3;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c250); }
          }
        }
      }
      if (s2 !== peg$FAILED) {
        s1 = input.substring(s1, peg$currPos);
      } else {
        s1 = s2;
      }
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c251) {
          s2 = peg$c251;
          peg$currPos += 4;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c252); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          peg$silentFails++;
          s4 = peg$parsechar();
          peg$silentFails--;
          if (s4 === peg$FAILED) {
            s3 = void 0;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseskip_space();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c254(s1);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseverb() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

      s0 = peg$currPos;
      if (input.substr(peg$currPos, 4) === peg$c255) {
        s1 = peg$c255;
        peg$currPos += 4;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c256); }
      }
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 42) {
          s2 = peg$c98;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c99); }
        }
        if (s2 === peg$FAILED) {
          s2 = null;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s4 = peg$currPos;
            peg$silentFails++;
            s5 = peg$parsechar();
            peg$silentFails--;
            if (s5 === peg$FAILED) {
              s4 = void 0;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
              if (input.length > peg$currPos) {
                s5 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c2); }
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$currPos;
                s7 = [];
                s8 = peg$currPos;
                s9 = peg$currPos;
                peg$silentFails++;
                s10 = peg$parsenl();
                peg$silentFails--;
                if (s10 === peg$FAILED) {
                  s9 = void 0;
                } else {
                  peg$currPos = s9;
                  s9 = peg$FAILED;
                }
                if (s9 !== peg$FAILED) {
                  if (input.length > peg$currPos) {
                    s10 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s10 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c2); }
                  }
                  if (s10 !== peg$FAILED) {
                    peg$savedPos = peg$currPos;
                    s11 = peg$c257(s2, s5, s10);
                    if (s11) {
                      s11 = peg$FAILED;
                    } else {
                      s11 = void 0;
                    }
                    if (s11 !== peg$FAILED) {
                      s9 = [s9, s10, s11];
                      s8 = s9;
                    } else {
                      peg$currPos = s8;
                      s8 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s8;
                  s8 = peg$FAILED;
                }
                while (s8 !== peg$FAILED) {
                  s7.push(s8);
                  s8 = peg$currPos;
                  s9 = peg$currPos;
                  peg$silentFails++;
                  s10 = peg$parsenl();
                  peg$silentFails--;
                  if (s10 === peg$FAILED) {
                    s9 = void 0;
                  } else {
                    peg$currPos = s9;
                    s9 = peg$FAILED;
                  }
                  if (s9 !== peg$FAILED) {
                    if (input.length > peg$currPos) {
                      s10 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s10 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c2); }
                    }
                    if (s10 !== peg$FAILED) {
                      peg$savedPos = peg$currPos;
                      s11 = peg$c257(s2, s5, s10);
                      if (s11) {
                        s11 = peg$FAILED;
                      } else {
                        s11 = void 0;
                      }
                      if (s11 !== peg$FAILED) {
                        s9 = [s9, s10, s11];
                        s8 = s9;
                      } else {
                        peg$currPos = s8;
                        s8 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s8;
                      s8 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s8;
                    s8 = peg$FAILED;
                  }
                }
                if (s7 !== peg$FAILED) {
                  s6 = input.substring(s6, peg$currPos);
                } else {
                  s6 = s7;
                }
                if (s6 !== peg$FAILED) {
                  if (input.length > peg$currPos) {
                    s7 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c2); }
                  }
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c258(s2, s5, s6, s7);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsebegin_env() {
      var s0, s1, s2, s3, s4, s5;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = peg$parsebegin();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsebegin_group();
        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$parseidentifier();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s3;
            s4 = peg$c260(s4);
          }
          s3 = s4;
          if (s3 !== peg$FAILED) {
            s4 = peg$parsenextArgStar();
            if (s4 === peg$FAILED) {
              s4 = null;
            }
            if (s4 !== peg$FAILED) {
              s5 = peg$parseend_group();
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c261(s3, s4);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c259); }
      }

      return s0;
    }

    function peg$parseend_env() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = peg$parseskip_all_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parseescape();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseend();
          if (s3 !== peg$FAILED) {
            s4 = peg$parsebegin_group();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseidentifier();
              if (s5 !== peg$FAILED) {
                s6 = peg$parseskip_space();
                if (s6 !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 42) {
                    s7 = peg$c98;
                    peg$currPos++;
                  } else {
                    s7 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c99); }
                  }
                  if (s7 === peg$FAILED) {
                    s7 = null;
                  }
                  if (s7 !== peg$FAILED) {
                    s8 = peg$parseend_group();
                    if (s8 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c263(s5, s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c262); }
      }

      return s0;
    }

    function peg$parseh_environment() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8;

      s0 = peg$currPos;
      s1 = peg$parsebegin_env();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsemacro_args();
        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$currPos;
          peg$silentFails++;
          if (input.length > peg$currPos) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c2); }
          }
          peg$silentFails--;
          if (s5 !== peg$FAILED) {
            peg$currPos = s4;
            s4 = void 0;
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s3;
            s4 = peg$c264(s1);
          }
          s3 = s4;
          if (s3 !== peg$FAILED) {
            s4 = peg$currPos;
            s5 = peg$parsespace();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s4;
              s5 = peg$c265(s1, s3, s5);
            }
            s4 = s5;
            if (s4 !== peg$FAILED) {
              s5 = [];
              s6 = peg$parseparagraph_with_linebreak();
              while (s6 !== peg$FAILED) {
                s5.push(s6);
                s6 = peg$parseparagraph_with_linebreak();
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parseend_env();
                if (s6 !== peg$FAILED) {
                  s7 = peg$currPos;
                  s8 = peg$parsespace();
                  if (s8 === peg$FAILED) {
                    s8 = null;
                  }
                  if (s8 !== peg$FAILED) {
                    peg$savedPos = s7;
                    s8 = peg$c266(s1, s3, s4, s5, s6, s8);
                  }
                  s7 = s8;
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c267(s1, s3, s4, s5, s6, s7);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseenvironment() {
      var s0, s1, s2, s3, s4, s5, s6;

      s0 = peg$currPos;
      s1 = peg$parsebegin_env();
      if (s1 !== peg$FAILED) {
        peg$savedPos = peg$currPos;
        s2 = peg$c268(s1);
        if (s2) {
          s2 = peg$FAILED;
        } else {
          s2 = void 0;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parsemacro_args();
          if (s3 !== peg$FAILED) {
            s4 = peg$currPos;
            s5 = peg$currPos;
            peg$silentFails++;
            if (input.length > peg$currPos) {
              s6 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c2); }
            }
            peg$silentFails--;
            if (s6 !== peg$FAILED) {
              peg$currPos = s5;
              s5 = void 0;
            } else {
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              peg$savedPos = s4;
              s5 = peg$c264(s1);
            }
            s4 = s5;
            if (s4 !== peg$FAILED) {
              s5 = [];
              s6 = peg$parseparagraph();
              while (s6 !== peg$FAILED) {
                s5.push(s6);
                s6 = peg$parseparagraph();
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parseend_env();
                if (s6 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c269(s1, s4, s5, s6);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseitem() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      s0 = peg$currPos;
      s1 = peg$parseskip_all_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parseescape();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c270) {
            s3 = peg$c270;
            peg$currPos += 4;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c271); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$currPos;
            peg$silentFails++;
            s5 = peg$parsechar();
            peg$silentFails--;
            if (s5 === peg$FAILED) {
              s4 = void 0;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
              peg$savedPos = peg$currPos;
              s5 = peg$c272();
              if (s5) {
                s5 = peg$FAILED;
              } else {
                s5 = void 0;
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parseopt_group();
                if (s6 === peg$FAILED) {
                  s6 = null;
                }
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseskip_all_space();
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c273(s6);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseitems() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      s0 = [];
      s1 = peg$currPos;
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseskip_all_space();
      if (s4 !== peg$FAILED) {
        s5 = peg$parsehv_macro();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseskip_all_space();
        if (s4 !== peg$FAILED) {
          s5 = peg$parsehv_macro();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$parseitem();
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$currPos;
          s6 = peg$currPos;
          peg$silentFails++;
          s7 = peg$parseitem();
          if (s7 === peg$FAILED) {
            s7 = peg$parseend_env();
          }
          peg$silentFails--;
          if (s7 === peg$FAILED) {
            s6 = void 0;
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parseparagraph();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s5;
              s6 = peg$c274(s3, s7);
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$currPos;
            s6 = peg$currPos;
            peg$silentFails++;
            s7 = peg$parseitem();
            if (s7 === peg$FAILED) {
              s7 = peg$parseend_env();
            }
            peg$silentFails--;
            if (s7 === peg$FAILED) {
              s6 = void 0;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parseparagraph();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s5;
                s6 = peg$c274(s3, s7);
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s1;
            s2 = peg$c275(s3, s4);
            s1 = s2;
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
      while (s1 !== peg$FAILED) {
        s0.push(s1);
        s1 = peg$currPos;
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$parseskip_all_space();
        if (s4 !== peg$FAILED) {
          s5 = peg$parsehv_macro();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parseskip_all_space();
          if (s4 !== peg$FAILED) {
            s5 = peg$parsehv_macro();
            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseitem();
          if (s3 !== peg$FAILED) {
            s4 = [];
            s5 = peg$currPos;
            s6 = peg$currPos;
            peg$silentFails++;
            s7 = peg$parseitem();
            if (s7 === peg$FAILED) {
              s7 = peg$parseend_env();
            }
            peg$silentFails--;
            if (s7 === peg$FAILED) {
              s6 = void 0;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parseparagraph();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s5;
                s6 = peg$c274(s3, s7);
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$currPos;
              s6 = peg$currPos;
              peg$silentFails++;
              s7 = peg$parseitem();
              if (s7 === peg$FAILED) {
                s7 = peg$parseend_env();
              }
              peg$silentFails--;
              if (s7 === peg$FAILED) {
                s6 = void 0;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseparagraph();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s5;
                  s6 = peg$c274(s3, s7);
                  s5 = s6;
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            }
            if (s4 !== peg$FAILED) {
              peg$savedPos = s1;
              s2 = peg$c275(s3, s4);
              s1 = s2;
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      }

      return s0;
    }

    function peg$parseenumitems() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      s0 = [];
      s1 = peg$currPos;
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parseskip_all_space();
      if (s4 !== peg$FAILED) {
        s5 = peg$parsehv_macro();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parseskip_all_space();
        if (s4 !== peg$FAILED) {
          s5 = peg$parsehv_macro();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (s2 !== peg$FAILED) {
        s3 = peg$currPos;
        s4 = peg$parseitem();
        if (s4 !== peg$FAILED) {
          peg$savedPos = s3;
          s4 = peg$c276(s4);
        }
        s3 = s4;
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$currPos;
          s6 = peg$currPos;
          peg$silentFails++;
          s7 = peg$parseitem();
          if (s7 === peg$FAILED) {
            s7 = peg$parseend_env();
          }
          peg$silentFails--;
          if (s7 === peg$FAILED) {
            s6 = void 0;
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          if (s6 !== peg$FAILED) {
            s7 = peg$parseparagraph();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s5;
              s6 = peg$c274(s3, s7);
              s5 = s6;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$currPos;
            s6 = peg$currPos;
            peg$silentFails++;
            s7 = peg$parseitem();
            if (s7 === peg$FAILED) {
              s7 = peg$parseend_env();
            }
            peg$silentFails--;
            if (s7 === peg$FAILED) {
              s6 = void 0;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parseparagraph();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s5;
                s6 = peg$c274(s3, s7);
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
          }
          if (s4 !== peg$FAILED) {
            peg$savedPos = s1;
            s2 = peg$c275(s3, s4);
            s1 = s2;
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
      while (s1 !== peg$FAILED) {
        s0.push(s1);
        s1 = peg$currPos;
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$parseskip_all_space();
        if (s4 !== peg$FAILED) {
          s5 = peg$parsehv_macro();
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$parseskip_all_space();
          if (s4 !== peg$FAILED) {
            s5 = peg$parsehv_macro();
            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$parseitem();
          if (s4 !== peg$FAILED) {
            peg$savedPos = s3;
            s4 = peg$c276(s4);
          }
          s3 = s4;
          if (s3 !== peg$FAILED) {
            s4 = [];
            s5 = peg$currPos;
            s6 = peg$currPos;
            peg$silentFails++;
            s7 = peg$parseitem();
            if (s7 === peg$FAILED) {
              s7 = peg$parseend_env();
            }
            peg$silentFails--;
            if (s7 === peg$FAILED) {
              s6 = void 0;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parseparagraph();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s5;
                s6 = peg$c274(s3, s7);
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$currPos;
              s6 = peg$currPos;
              peg$silentFails++;
              s7 = peg$parseitem();
              if (s7 === peg$FAILED) {
                s7 = peg$parseend_env();
              }
              peg$silentFails--;
              if (s7 === peg$FAILED) {
                s6 = void 0;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
              if (s6 !== peg$FAILED) {
                s7 = peg$parseparagraph();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s5;
                  s6 = peg$c274(s3, s7);
                  s5 = s6;
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            }
            if (s4 !== peg$FAILED) {
              peg$savedPos = s1;
              s2 = peg$c275(s3, s4);
              s1 = s2;
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      }

      return s0;
    }

    function peg$parsecomment_env() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      peg$silentFails++;
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 6) === peg$c278) {
        s1 = peg$c278;
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c279); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parseskip_space();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 9) === peg$c280) {
            s3 = peg$c280;
            peg$currPos += 9;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c281); }
          }
          if (s3 !== peg$FAILED) {
            s4 = [];
            s5 = peg$currPos;
            s6 = peg$currPos;
            peg$silentFails++;
            s7 = peg$parseend_comment();
            peg$silentFails--;
            if (s7 === peg$FAILED) {
              s6 = void 0;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
            if (s6 !== peg$FAILED) {
              if (input.length > peg$currPos) {
                s7 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c2); }
              }
              if (s7 !== peg$FAILED) {
                s6 = [s6, s7];
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$currPos;
              s6 = peg$currPos;
              peg$silentFails++;
              s7 = peg$parseend_comment();
              peg$silentFails--;
              if (s7 === peg$FAILED) {
                s6 = void 0;
              } else {
                peg$currPos = s6;
                s6 = peg$FAILED;
              }
              if (s6 !== peg$FAILED) {
                if (input.length > peg$currPos) {
                  s7 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s7 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c2); }
                }
                if (s7 !== peg$FAILED) {
                  s6 = [s6, s7];
                  s5 = s6;
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            }
            if (s4 !== peg$FAILED) {
              s5 = peg$parseend_comment();
              if (s5 !== peg$FAILED) {
                s6 = peg$parseskip_space();
                if (s6 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c282();
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c277); }
      }

      return s0;
    }

    function peg$parseend_comment() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      if (input.substr(peg$currPos, 4) === peg$c283) {
        s1 = peg$c283;
        peg$currPos += 4;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c284); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$parseskip_space();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 9) === peg$c280) {
            s3 = peg$c280;
            peg$currPos += 9;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c281); }
          }
          if (s3 !== peg$FAILED) {
            s1 = [s1, s2, s3];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsemath() {
      var s0;

      s0 = peg$parseinline_math();
      if (s0 === peg$FAILED) {
        s0 = peg$parsedisplay_math();
      }

      return s0;
    }

    function peg$parseinline_math() {
      var s0, s1, s2, s3, s4, s5;

      s0 = peg$currPos;
      s1 = peg$parsemath_shift();
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = [];
        s4 = peg$parsemath_primitive();
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$parsemath_primitive();
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s2 = input.substring(s2, peg$currPos);
        } else {
          s2 = s3;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parsemath_shift();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c285(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseescape();
        if (s1 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 40) {
            s2 = peg$c139;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c140); }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$currPos;
            s4 = [];
            s5 = peg$parsemath_primitive();
            if (s5 !== peg$FAILED) {
              while (s5 !== peg$FAILED) {
                s4.push(s5);
                s5 = peg$parsemath_primitive();
              }
            } else {
              s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
              s3 = input.substring(s3, peg$currPos);
            } else {
              s3 = s4;
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parseescape();
              if (s4 !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 41) {
                  s5 = peg$c141;
                  peg$currPos++;
                } else {
                  s5 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c142); }
                }
                if (s5 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c285(s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }

      return s0;
    }

    function peg$parsedisplay_math() {
      var s0, s1, s2, s3, s4, s5;

      s0 = peg$currPos;
      s1 = peg$parsemath_shift();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsemath_shift();
        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          s4 = [];
          s5 = peg$parsemath_primitive();
          if (s5 !== peg$FAILED) {
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              s5 = peg$parsemath_primitive();
            }
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s3 = input.substring(s3, peg$currPos);
          } else {
            s3 = s4;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parsemath_shift();
            if (s4 !== peg$FAILED) {
              s5 = peg$parsemath_shift();
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c286(s3);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseescape();
        if (s1 !== peg$FAILED) {
          s2 = peg$parseleft_br();
          if (s2 !== peg$FAILED) {
            s3 = peg$currPos;
            s4 = [];
            s5 = peg$parsemath_primitive();
            if (s5 !== peg$FAILED) {
              while (s5 !== peg$FAILED) {
                s4.push(s5);
                s5 = peg$parsemath_primitive();
              }
            } else {
              s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
              s3 = input.substring(s3, peg$currPos);
            } else {
              s3 = s4;
            }
            if (s3 !== peg$FAILED) {
              s4 = peg$parseescape();
              if (s4 !== peg$FAILED) {
                s5 = peg$parseright_br();
                if (s5 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c286(s3);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }

      return s0;
    }

    function peg$parsemath_primitive() {
      var s0, s1, s2, s3;

      s0 = peg$parseprimitive();
      if (s0 === peg$FAILED) {
        s0 = peg$parsealignment_tab();
        if (s0 === peg$FAILED) {
          s0 = peg$parsesuperscript();
          if (s0 === peg$FAILED) {
            s0 = peg$parsesubscript();
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parseescape();
              if (s1 !== peg$FAILED) {
                s2 = peg$parseidentifier();
                if (s2 !== peg$FAILED) {
                  s1 = [s1, s2];
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parsebegin_group();
                if (s1 !== peg$FAILED) {
                  s2 = peg$parseskip_space();
                  if (s2 !== peg$FAILED) {
                    s3 = peg$parseend_group();
                    if (s3 !== peg$FAILED) {
                      s1 = [s1, s2, s3];
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  s1 = peg$parsebegin_group();
                  if (s1 !== peg$FAILED) {
                    s2 = [];
                    s3 = peg$parsemath_primitive();
                    if (s3 !== peg$FAILED) {
                      while (s3 !== peg$FAILED) {
                        s2.push(s3);
                        s3 = peg$parsemath_primitive();
                      }
                    } else {
                      s2 = peg$FAILED;
                    }
                    if (s2 !== peg$FAILED) {
                      s3 = peg$parseend_group();
                      if (s3 !== peg$FAILED) {
                        s1 = [s1, s2, s3];
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                  if (s0 === peg$FAILED) {
                    s0 = peg$parsesp();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parsenl();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parselinebreak();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parsecomment();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parsebegin() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      if (input.substr(peg$currPos, 5) === peg$c287) {
        s1 = peg$c287;
        peg$currPos += 5;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c288); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parsechar();
        peg$silentFails--;
        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c289();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseend() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      if (input.substr(peg$currPos, 3) === peg$c290) {
        s1 = peg$c290;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c291); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parsechar();
        peg$silentFails--;
        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c289();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsepar() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      if (input.substr(peg$currPos, 3) === peg$c292) {
        s1 = peg$c292;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c293); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parsechar();
        peg$silentFails--;
        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c289();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsenoindent() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      if (input.substr(peg$currPos, 8) === peg$c294) {
        s1 = peg$c294;
        peg$currPos += 8;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c295); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parsechar();
        peg$silentFails--;
        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c289();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseplus() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      if (input.substr(peg$currPos, 4) === peg$c296) {
        s1 = peg$c296;
        peg$currPos += 4;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c297); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parsechar();
        peg$silentFails--;
        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c289();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseminus() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      if (input.substr(peg$currPos, 5) === peg$c298) {
        s1 = peg$c298;
        peg$currPos += 5;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c299); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parsechar();
        peg$silentFails--;
        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c289();
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseendinput() {
      var s0, s1, s2, s3, s4, s5;

      s0 = peg$currPos;
      if (input.substr(peg$currPos, 8) === peg$c300) {
        s1 = peg$c300;
        peg$currPos += 8;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c301); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parsechar();
        peg$silentFails--;
        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s4 = [];
            if (input.length > peg$currPos) {
              s5 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c2); }
            }
            while (s5 !== peg$FAILED) {
              s4.push(s5);
              if (input.length > peg$currPos) {
                s5 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c2); }
              }
            }
            if (s4 !== peg$FAILED) {
              s1 = [s1, s2, s3, s4];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseescape() {
      var s0, s1;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 92) {
        s1 = peg$c302;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c303); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
      }
      s0 = s1;

      return s0;
    }

    function peg$parsebegin_group() {
      var s0, s1;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 123) {
        s1 = peg$c304;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c305); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
      }
      s0 = s1;

      return s0;
    }

    function peg$parseend_group() {
      var s0, s1;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 125) {
        s1 = peg$c306;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c307); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
      }
      s0 = s1;

      return s0;
    }

    function peg$parsemath_shift() {
      var s0, s1;

      peg$silentFails++;
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 36) {
        s1 = peg$c309;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c310); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c308); }
      }

      return s0;
    }

    function peg$parsealignment_tab() {
      var s0, s1;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 38) {
        s1 = peg$c311;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c312); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
      }
      s0 = s1;

      return s0;
    }

    function peg$parsemacro_parameter() {
      var s0, s1;

      peg$silentFails++;
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 35) {
        s1 = peg$c314;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c315); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c313); }
      }

      return s0;
    }

    function peg$parsesuperscript() {
      var s0, s1;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 94) {
        s1 = peg$c316;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c317); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
      }
      s0 = s1;

      return s0;
    }

    function peg$parsesubscript() {
      var s0, s1;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 95) {
        s1 = peg$c318;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c319); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
      }
      s0 = s1;

      return s0;
    }

    function peg$parseignore() {
      var s0, s1;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 0) {
        s1 = peg$c320;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c321); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
      }
      s0 = s1;

      return s0;
    }

    function peg$parseEOF() {
      var s0, s1, s2;

      peg$silentFails++;
      s0 = peg$currPos;
      peg$silentFails++;
      if (input.length > peg$currPos) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c2); }
      }
      peg$silentFails--;
      if (s1 === peg$FAILED) {
        s0 = void 0;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseescape();
        if (s1 !== peg$FAILED) {
          s2 = peg$parseendinput();
          if (s2 !== peg$FAILED) {
            s1 = [s1, s2];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c322); }
      }

      return s0;
    }

    function peg$parsenl() {
      var s0, s1;

      peg$silentFails++;
      if (input.charCodeAt(peg$currPos) === 10) {
        s0 = peg$c324;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c325); }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c326) {
          s0 = peg$c326;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c327); }
        }
        if (s0 === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 13) {
            s0 = peg$c328;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c329); }
          }
          if (s0 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 8232) {
              s0 = peg$c330;
              peg$currPos++;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c331); }
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.charCodeAt(peg$currPos) === 8233) {
                s1 = peg$c332;
                peg$currPos++;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c333); }
              }
              if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c11();
              }
              s0 = s1;
            }
          }
        }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c323); }
      }

      return s0;
    }

    function peg$parsesp() {
      var s0, s1;

      peg$silentFails++;
      s0 = peg$currPos;
      if (peg$c335.test(input.charAt(peg$currPos))) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c336); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c334); }
      }

      return s0;
    }

    function peg$parsecomment() {
      var s0, s1, s2, s3, s4, s5, s6;

      peg$silentFails++;
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 37) {
        s1 = peg$c144;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c145); }
      }
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$currPos;
        peg$silentFails++;
        s5 = peg$parsenl();
        peg$silentFails--;
        if (s5 === peg$FAILED) {
          s4 = void 0;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c2); }
          }
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$currPos;
          peg$silentFails++;
          s5 = peg$parsenl();
          peg$silentFails--;
          if (s5 === peg$FAILED) {
            s4 = void 0;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            if (input.length > peg$currPos) {
              s5 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c2); }
            }
            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$parsenl();
          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = peg$parsesp();
            while (s6 !== peg$FAILED) {
              s5.push(s6);
              s6 = peg$parsesp();
            }
            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
          if (s3 === peg$FAILED) {
            s3 = peg$parseEOF();
          }
          if (s3 !== peg$FAILED) {
            s1 = [s1, s2, s3];
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parsecomment_env();
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c11();
        }
        s0 = s1;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c337); }
      }

      return s0;
    }

    function peg$parseskip_space() {
      var s0, s1, s2, s3, s4;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$currPos;
      s3 = peg$currPos;
      peg$silentFails++;
      s4 = peg$parsebreak();
      peg$silentFails--;
      if (s4 === peg$FAILED) {
        s3 = void 0;
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$parsenl();
        if (s4 === peg$FAILED) {
          s4 = peg$parsesp();
          if (s4 === peg$FAILED) {
            s4 = peg$parsecomment();
          }
        }
        if (s4 !== peg$FAILED) {
          s3 = [s3, s4];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$currPos;
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = peg$parsebreak();
        peg$silentFails--;
        if (s4 === peg$FAILED) {
          s3 = void 0;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$parsenl();
          if (s4 === peg$FAILED) {
            s4 = peg$parsesp();
            if (s4 === peg$FAILED) {
              s4 = peg$parsecomment();
            }
          }
          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c338); }
      }

      return s0;
    }

    function peg$parseskip_all_space() {
      var s0, s1, s2;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parsenl();
      if (s2 === peg$FAILED) {
        s2 = peg$parsesp();
        if (s2 === peg$FAILED) {
          s2 = peg$parsecomment();
        }
      }
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parsenl();
        if (s2 === peg$FAILED) {
          s2 = peg$parsesp();
          if (s2 === peg$FAILED) {
            s2 = peg$parsecomment();
          }
        }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c338); }
      }

      return s0;
    }

    function peg$parsespace() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = peg$currPos;
      peg$silentFails++;
      s2 = peg$parsebreak();
      peg$silentFails--;
      if (s2 === peg$FAILED) {
        s1 = void 0;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parselinebreak();
        peg$silentFails--;
        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          peg$silentFails++;
          s4 = peg$currPos;
          s5 = peg$parseskip_all_space();
          if (s5 !== peg$FAILED) {
            s6 = peg$parseescape();
            if (s6 !== peg$FAILED) {
              s7 = peg$parseis_vmode();
              if (s7 === peg$FAILED) {
                s7 = peg$parseis_vmode_env();
              }
              if (s7 !== peg$FAILED) {
                s5 = [s5, s6, s7];
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          peg$silentFails--;
          if (s4 === peg$FAILED) {
            s3 = void 0;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s4 = [];
            s5 = peg$parsesp();
            if (s5 === peg$FAILED) {
              s5 = peg$parsenl();
            }
            if (s5 !== peg$FAILED) {
              while (s5 !== peg$FAILED) {
                s4.push(s5);
                s5 = peg$parsesp();
                if (s5 === peg$FAILED) {
                  s5 = peg$parsenl();
                }
              }
            } else {
              s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c339();
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c338); }
      }

      return s0;
    }

    function peg$parsectrl_space() {
      var s0, s1, s2, s3, s4, s5;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$currPos;
        peg$silentFails++;
        s4 = peg$parsenl();
        peg$silentFails--;
        if (s4 !== peg$FAILED) {
          peg$currPos = s3;
          s3 = void 0;
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = peg$currPos;
          peg$silentFails++;
          s5 = peg$parsebreak();
          peg$silentFails--;
          if (s5 !== peg$FAILED) {
            peg$currPos = s4;
            s4 = void 0;
          } else {
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            s3 = [s3, s4];
            s2 = s3;
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 === peg$FAILED) {
          s2 = peg$parsenl();
          if (s2 === peg$FAILED) {
            s2 = peg$parsesp();
          }
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c339();
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c340); }
      }

      return s0;
    }

    function peg$parsenbsp() {
      var s0, s1;

      peg$silentFails++;
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 126) {
        s1 = peg$c342;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c343); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c344();
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c341); }
      }

      return s0;
    }

    function peg$parsebreak() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = [];
      s2 = peg$currPos;
      s3 = peg$parseskip_all_space();
      if (s3 !== peg$FAILED) {
        s4 = peg$parseescape();
        if (s4 !== peg$FAILED) {
          s5 = peg$parsepar();
          if (s5 !== peg$FAILED) {
            s6 = peg$parseskip_all_space();
            if (s6 !== peg$FAILED) {
              s3 = [s3, s4, s5, s6];
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$currPos;
          s3 = peg$parseskip_all_space();
          if (s3 !== peg$FAILED) {
            s4 = peg$parseescape();
            if (s4 !== peg$FAILED) {
              s5 = peg$parsepar();
              if (s5 !== peg$FAILED) {
                s6 = peg$parseskip_all_space();
                if (s6 !== peg$FAILED) {
                  s3 = [s3, s4, s5, s6];
                  s2 = s3;
                } else {
                  peg$currPos = s2;
                  s2 = peg$FAILED;
                }
              } else {
                peg$currPos = s2;
                s2 = peg$FAILED;
              }
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        }
      } else {
        s1 = peg$FAILED;
      }
      if (s1 === peg$FAILED) {
        s1 = peg$currPos;
        s2 = [];
        s3 = peg$parsesp();
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parsesp();
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$parsenl();
          if (s4 !== peg$FAILED) {
            s5 = [];
            s6 = peg$parsecomment();
            while (s6 !== peg$FAILED) {
              s5.push(s6);
              s6 = peg$parsecomment();
            }
            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
          if (s3 === peg$FAILED) {
            s3 = [];
            s4 = peg$parsecomment();
            if (s4 !== peg$FAILED) {
              while (s4 !== peg$FAILED) {
                s3.push(s4);
                s4 = peg$parsecomment();
              }
            } else {
              s3 = peg$FAILED;
            }
          }
          if (s3 !== peg$FAILED) {
            s4 = [];
            s5 = peg$currPos;
            s6 = [];
            s7 = peg$parsesp();
            while (s7 !== peg$FAILED) {
              s6.push(s7);
              s7 = peg$parsesp();
            }
            if (s6 !== peg$FAILED) {
              s7 = peg$parsenl();
              if (s7 !== peg$FAILED) {
                s6 = [s6, s7];
                s5 = s6;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              while (s5 !== peg$FAILED) {
                s4.push(s5);
                s5 = peg$currPos;
                s6 = [];
                s7 = peg$parsesp();
                while (s7 !== peg$FAILED) {
                  s6.push(s7);
                  s7 = peg$parsesp();
                }
                if (s6 !== peg$FAILED) {
                  s7 = peg$parsenl();
                  if (s7 !== peg$FAILED) {
                    s6 = [s6, s7];
                    s5 = s6;
                  } else {
                    peg$currPos = s5;
                    s5 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              }
            } else {
              s4 = peg$FAILED;
            }
            if (s4 === peg$FAILED) {
              s4 = peg$currPos;
              peg$silentFails++;
              s5 = peg$parseend_doc();
              peg$silentFails--;
              if (s5 !== peg$FAILED) {
                peg$currPos = s4;
                s4 = void 0;
              } else {
                s4 = peg$FAILED;
              }
              if (s4 === peg$FAILED) {
                s4 = peg$parseEOF();
              }
            }
            if (s4 !== peg$FAILED) {
              s5 = [];
              s6 = peg$parsesp();
              if (s6 === peg$FAILED) {
                s6 = peg$parsenl();
                if (s6 === peg$FAILED) {
                  s6 = peg$parsecomment();
                }
              }
              while (s6 !== peg$FAILED) {
                s5.push(s6);
                s6 = peg$parsesp();
                if (s6 === peg$FAILED) {
                  s6 = peg$parsenl();
                  if (s6 === peg$FAILED) {
                    s6 = peg$parsecomment();
                  }
                }
              }
              if (s5 !== peg$FAILED) {
                s2 = [s2, s3, s4, s5];
                s1 = s2;
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c346();
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c345); }
      }

      return s0;
    }

    function peg$parselinebreak() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parseescape();
        if (s2 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 92) {
            s3 = peg$c302;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c303); }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseskip_space();
            if (s4 !== peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 42) {
                s5 = peg$c98;
                peg$currPos++;
              } else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c99); }
              }
              if (s5 === peg$FAILED) {
                s5 = null;
              }
              if (s5 !== peg$FAILED) {
                s6 = peg$parseskip_space();
                if (s6 !== peg$FAILED) {
                  s7 = peg$currPos;
                  s8 = peg$parsebegin_optgroup();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parseskip_space();
                    if (s9 !== peg$FAILED) {
                      s10 = peg$parselength();
                      if (s10 !== peg$FAILED) {
                        s11 = peg$parseend_optgroup();
                        if (s11 !== peg$FAILED) {
                          s12 = peg$parseskip_space();
                          if (s12 !== peg$FAILED) {
                            peg$savedPos = s7;
                            s8 = peg$c348(s10);
                            s7 = s8;
                          } else {
                            peg$currPos = s7;
                            s7 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s7;
                          s7 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s7;
                        s7 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s7;
                      s7 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s7;
                    s7 = peg$FAILED;
                  }
                  if (s7 === peg$FAILED) {
                    s7 = null;
                  }
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c349(s7);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c347); }
      }

      return s0;
    }

    function peg$parseunskip_macro() {
      var s0, s1, s2, s3, s4, s5;

      s0 = peg$currPos;
      s1 = peg$parseskip_space();
      if (s1 !== peg$FAILED) {
        s2 = peg$parseescape();
        if (s2 !== peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c350) {
            s3 = peg$c350;
            peg$currPos += 3;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c351); }
          }
          if (s3 === peg$FAILED) {
            if (input.substr(peg$currPos, 7) === peg$c352) {
              s3 = peg$c352;
              peg$currPos += 7;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c353); }
            }
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$currPos;
            peg$silentFails++;
            s5 = peg$parsechar();
            peg$silentFails--;
            if (s5 === peg$FAILED) {
              s4 = void 0;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
            if (s4 !== peg$FAILED) {
              s1 = [s1, s2, s3, s4];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsebegin_optgroup() {
      var s0, s1;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 91) {
        s1 = peg$c191;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c192); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
      }
      s0 = s1;

      return s0;
    }

    function peg$parseend_optgroup() {
      var s0, s1;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 93) {
        s1 = peg$c193;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c194); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c11();
      }
      s0 = s1;

      return s0;
    }

    function peg$parsechar() {
      var s0, s1;

      peg$silentFails++;
      s0 = peg$currPos;
      if (peg$c355.test(input.charAt(peg$currPos))) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c356); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c357(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c354); }
      }

      return s0;
    }

    function peg$parsedigit() {
      var s0, s1;

      peg$silentFails++;
      s0 = peg$currPos;
      if (peg$c359.test(input.charAt(peg$currPos))) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c360); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c361(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c358); }
      }

      return s0;
    }

    function peg$parsepunctuation() {
      var s0, s1;

      peg$silentFails++;
      s0 = peg$currPos;
      if (peg$c363.test(input.charAt(peg$currPos))) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c364); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c365(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c362); }
      }

      return s0;
    }

    function peg$parsequotes() {
      var s0, s1;

      peg$silentFails++;
      s0 = peg$currPos;
      if (peg$c367.test(input.charAt(peg$currPos))) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c368); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c369(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c366); }
      }

      return s0;
    }

    function peg$parseleft_br() {
      var s0, s1;

      peg$silentFails++;
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 91) {
        s1 = peg$c191;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c192); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c371(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c370); }
      }

      return s0;
    }

    function peg$parseright_br() {
      var s0, s1;

      peg$silentFails++;
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 93) {
        s1 = peg$c193;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c194); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c371(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c372); }
      }

      return s0;
    }

    function peg$parseutf8_char() {
      var s0, s1, s2;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = peg$currPos;
      peg$silentFails++;
      s2 = peg$parsesp();
      if (s2 === peg$FAILED) {
        s2 = peg$parsenl();
        if (s2 === peg$FAILED) {
          s2 = peg$parseescape();
          if (s2 === peg$FAILED) {
            s2 = peg$parsebegin_group();
            if (s2 === peg$FAILED) {
              s2 = peg$parseend_group();
              if (s2 === peg$FAILED) {
                s2 = peg$parsemath_shift();
                if (s2 === peg$FAILED) {
                  s2 = peg$parsealignment_tab();
                  if (s2 === peg$FAILED) {
                    s2 = peg$parsemacro_parameter();
                    if (s2 === peg$FAILED) {
                      s2 = peg$parsesuperscript();
                      if (s2 === peg$FAILED) {
                        s2 = peg$parsesubscript();
                        if (s2 === peg$FAILED) {
                          s2 = peg$parseignore();
                          if (s2 === peg$FAILED) {
                            s2 = peg$parsecomment();
                            if (s2 === peg$FAILED) {
                              s2 = peg$parsebegin_optgroup();
                              if (s2 === peg$FAILED) {
                                s2 = peg$parseend_optgroup();
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      peg$silentFails--;
      if (s2 === peg$FAILED) {
        s1 = void 0;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        if (input.length > peg$currPos) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c2); }
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c374(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c373); }
      }

      return s0;
    }

    function peg$parsehyphen() {
      var s0, s1;

      peg$silentFails++;
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 45) {
        s1 = peg$c169;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c170); }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c376();
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c375); }
      }

      return s0;
    }

    function peg$parseligature() {
      var s0, s1;

      peg$silentFails++;
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 3) === peg$c378) {
        s1 = peg$c378;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c379); }
      }
      if (s1 === peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c380) {
          s1 = peg$c380;
          peg$currPos += 3;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c381); }
        }
        if (s1 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c382) {
            s1 = peg$c382;
            peg$currPos += 2;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c383); }
          }
          if (s1 === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c384) {
              s1 = peg$c384;
              peg$currPos += 2;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c385); }
            }
            if (s1 === peg$FAILED) {
              if (input.substr(peg$currPos, 2) === peg$c386) {
                s1 = peg$c386;
                peg$currPos += 2;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c387); }
              }
              if (s1 === peg$FAILED) {
                if (input.substr(peg$currPos, 3) === peg$c388) {
                  s1 = peg$c388;
                  peg$currPos += 3;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c389); }
                }
                if (s1 === peg$FAILED) {
                  if (input.substr(peg$currPos, 2) === peg$c390) {
                    s1 = peg$c390;
                    peg$currPos += 2;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c391); }
                  }
                  if (s1 === peg$FAILED) {
                    if (input.substr(peg$currPos, 2) === peg$c392) {
                      s1 = peg$c392;
                      peg$currPos += 2;
                    } else {
                      s1 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c393); }
                    }
                    if (s1 === peg$FAILED) {
                      if (input.substr(peg$currPos, 2) === peg$c394) {
                        s1 = peg$c394;
                        peg$currPos += 2;
                      } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c395); }
                      }
                      if (s1 === peg$FAILED) {
                        if (input.substr(peg$currPos, 2) === peg$c396) {
                          s1 = peg$c396;
                          peg$currPos += 2;
                        } else {
                          s1 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c397); }
                        }
                        if (s1 === peg$FAILED) {
                          if (input.substr(peg$currPos, 2) === peg$c398) {
                            s1 = peg$c398;
                            peg$currPos += 2;
                          } else {
                            s1 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c399); }
                          }
                          if (s1 === peg$FAILED) {
                            if (input.substr(peg$currPos, 2) === peg$c400) {
                              s1 = peg$c400;
                              peg$currPos += 2;
                            } else {
                              s1 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c401); }
                            }
                            if (s1 === peg$FAILED) {
                              if (input.substr(peg$currPos, 2) === peg$c402) {
                                s1 = peg$c402;
                                peg$currPos += 2;
                              } else {
                                s1 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c403); }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c404(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c377); }
      }

      return s0;
    }

    function peg$parsectrl_sym() {
      var s0, s1, s2;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        if (peg$c406.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c407); }
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c408(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c405); }
      }

      return s0;
    }

    function peg$parsesymbol() {
      var s0, s1, s2, s3, s4;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        s2 = peg$parseidentifier();
        if (s2 !== peg$FAILED) {
          peg$savedPos = peg$currPos;
          s3 = peg$c410(s2);
          if (s3) {
            s3 = void 0;
          } else {
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseskip_space();
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c411(s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c409); }
      }

      return s0;
    }

    function peg$parsediacritic() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        s3 = peg$currPos;
        s4 = peg$parsechar();
        if (s4 !== peg$FAILED) {
          s5 = peg$currPos;
          peg$silentFails++;
          s6 = peg$parsechar();
          peg$silentFails--;
          if (s6 === peg$FAILED) {
            s5 = void 0;
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 === peg$FAILED) {
          s3 = peg$currPos;
          s4 = peg$currPos;
          peg$silentFails++;
          s5 = peg$parsechar();
          peg$silentFails--;
          if (s5 === peg$FAILED) {
            s4 = void 0;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            if (input.length > peg$currPos) {
              s5 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c2); }
            }
            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        if (s3 !== peg$FAILED) {
          s2 = input.substring(s2, peg$currPos);
        } else {
          s2 = s3;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = peg$currPos;
          s3 = peg$c413(s2);
          if (s3) {
            s3 = void 0;
          } else {
            s3 = peg$FAILED;
          }
          if (s3 !== peg$FAILED) {
            s4 = peg$parseskip_space();
            if (s4 !== peg$FAILED) {
              s5 = peg$currPos;
              s6 = peg$parsebegin_group();
              if (s6 !== peg$FAILED) {
                s7 = peg$parseprimitive();
                if (s7 === peg$FAILED) {
                  s7 = null;
                }
                if (s7 !== peg$FAILED) {
                  s8 = peg$parseend_group();
                  if (s8 !== peg$FAILED) {
                    s9 = peg$parsespace();
                    if (s9 === peg$FAILED) {
                      s9 = null;
                    }
                    if (s9 !== peg$FAILED) {
                      peg$savedPos = s5;
                      s6 = peg$c414(s2, s7, s9);
                      s5 = s6;
                    } else {
                      peg$currPos = s5;
                      s5 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s5;
                    s5 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
              if (s5 === peg$FAILED) {
                s5 = peg$currPos;
                s6 = peg$parseprimitive();
                if (s6 !== peg$FAILED) {
                  peg$savedPos = s5;
                  s6 = peg$c415(s2, s6);
                }
                s5 = s6;
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c416(s2, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c412); }
      }

      return s0;
    }

    function peg$parsecharsym() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      s0 = peg$currPos;
      s1 = peg$parseescape();
      if (s1 !== peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c417) {
          s2 = peg$c417;
          peg$currPos += 6;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c418); }
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parsebegin_group();
          if (s3 !== peg$FAILED) {
            s4 = peg$parseskip_space();
            if (s4 !== peg$FAILED) {
              s5 = peg$parseinteger();
              if (s5 !== peg$FAILED) {
                s6 = peg$parseskip_space();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseend_group();
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c419(s5);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseescape();
        if (s1 !== peg$FAILED) {
          if (input.substr(peg$currPos, 4) === peg$c420) {
            s2 = peg$c420;
            peg$currPos += 4;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c421); }
          }
          if (s2 !== peg$FAILED) {
            s3 = peg$parseinteger();
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c419(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 4) === peg$c422) {
            s1 = peg$c422;
            peg$currPos += 4;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c423); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parsehex16();
            if (s2 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c419(s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 2) === peg$c424) {
              s1 = peg$c424;
              peg$currPos += 2;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c425); }
            }
            if (s1 !== peg$FAILED) {
              s2 = peg$parsehex8();
              if (s2 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c419(s2);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.substr(peg$currPos, 2) === peg$c424) {
                s1 = peg$c424;
                peg$currPos += 2;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c425); }
              }
              if (s1 !== peg$FAILED) {
                if (input.length > peg$currPos) {
                  s2 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s2 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c2); }
                }
                if (s2 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c426(s2);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parseinteger() {
      var s0, s1, s2;

      s0 = peg$currPos;
      s1 = peg$parseint();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c427(s1);
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 39) {
          s1 = peg$c149;
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c150); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parseoct();
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c428(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 34) {
            s1 = peg$c429;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c430); }
          }
          if (s1 !== peg$FAILED) {
            s2 = peg$parsehex16();
            if (s2 === peg$FAILED) {
              s2 = peg$parsehex8();
            }
            if (s2 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c431(s2);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }
      }

      return s0;
    }

    function peg$parsehex8() {
      var s0, s1, s2, s3, s4;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = peg$currPos;
      s2 = peg$currPos;
      s3 = peg$parsehex();
      if (s3 !== peg$FAILED) {
        s4 = peg$parsehex();
        if (s4 !== peg$FAILED) {
          s3 = [s3, s4];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s1 = input.substring(s1, peg$currPos);
      } else {
        s1 = s2;
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c433(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c432); }
      }

      return s0;
    }

    function peg$parsehex16() {
      var s0, s1, s2, s3, s4, s5, s6;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = peg$currPos;
      s2 = peg$currPos;
      s3 = peg$parsehex();
      if (s3 !== peg$FAILED) {
        s4 = peg$parsehex();
        if (s4 !== peg$FAILED) {
          s5 = peg$parsehex();
          if (s5 !== peg$FAILED) {
            s6 = peg$parsehex();
            if (s6 !== peg$FAILED) {
              s3 = [s3, s4, s5, s6];
              s2 = s3;
            } else {
              peg$currPos = s2;
              s2 = peg$FAILED;
            }
          } else {
            peg$currPos = s2;
            s2 = peg$FAILED;
          }
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s1 = input.substring(s1, peg$currPos);
      } else {
        s1 = s2;
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c433(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c434); }
      }

      return s0;
    }

    function peg$parseint() {
      var s0, s1, s2;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = [];
      if (peg$c359.test(input.charAt(peg$currPos))) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c360); }
      }
      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          if (peg$c359.test(input.charAt(peg$currPos))) {
            s2 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c360); }
          }
        }
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s0 = input.substring(s0, peg$currPos);
      } else {
        s0 = s1;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c435); }
      }

      return s0;
    }

    function peg$parseoct() {
      var s0, s1, s2;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = [];
      if (peg$c437.test(input.charAt(peg$currPos))) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c438); }
      }
      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          if (peg$c437.test(input.charAt(peg$currPos))) {
            s2 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c438); }
          }
        }
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s0 = input.substring(s0, peg$currPos);
      } else {
        s0 = s1;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c436); }
      }

      return s0;
    }

    function peg$parsehex() {
      var s0, s1;

      peg$silentFails++;
      if (peg$c440.test(input.charAt(peg$currPos))) {
        s0 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c441); }
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c439); }
      }

      return s0;
    }

    function peg$parsefloat() {
      var s0, s1, s2, s3, s4, s5, s6, s7, s8;

      peg$silentFails++;
      s0 = peg$currPos;
      s1 = peg$currPos;
      s2 = peg$currPos;
      if (peg$c443.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c444); }
      }
      if (s3 === peg$FAILED) {
        s3 = null;
      }
      if (s3 !== peg$FAILED) {
        s4 = peg$currPos;
        s5 = peg$parseint();
        if (s5 !== peg$FAILED) {
          s6 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 46) {
            s7 = peg$c187;
            peg$currPos++;
          } else {
            s7 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c188); }
          }
          if (s7 !== peg$FAILED) {
            s8 = peg$parseint();
            if (s8 === peg$FAILED) {
              s8 = null;
            }
            if (s8 !== peg$FAILED) {
              s7 = [s7, s8];
              s6 = s7;
            } else {
              peg$currPos = s6;
              s6 = peg$FAILED;
            }
          } else {
            peg$currPos = s6;
            s6 = peg$FAILED;
          }
          if (s6 === peg$FAILED) {
            s6 = null;
          }
          if (s6 !== peg$FAILED) {
            s5 = [s5, s6];
            s4 = s5;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 === peg$FAILED) {
          s4 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 46) {
            s5 = peg$c187;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c188); }
          }
          if (s5 !== peg$FAILED) {
            s6 = peg$parseint();
            if (s6 !== peg$FAILED) {
              s5 = [s5, s6];
              s4 = s5;
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
        }
        if (s4 !== peg$FAILED) {
          s3 = [s3, s4];
          s2 = s3;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s1 = input.substring(s1, peg$currPos);
      } else {
        s1 = s2;
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c445(s1);
      }
      s0 = s1;
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c442); }
      }

      return s0;
    }

    function peg$parsethe() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      s0 = peg$currPos;
      if (input.substr(peg$currPos, 3) === peg$c446) {
        s1 = peg$c446;
        peg$currPos += 3;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c447); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parsechar();
        peg$silentFails--;
        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s4 = peg$currPos;
            s5 = peg$parsevalue();
            if (s5 !== peg$FAILED) {
              peg$savedPos = peg$currPos;
              s6 = peg$c448(s5);
              if (s6) {
                s6 = void 0;
              } else {
                s6 = peg$FAILED;
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s4;
                s5 = peg$c449(s5);
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
            if (s4 === peg$FAILED) {
              s4 = peg$currPos;
              s5 = peg$parseescape();
              if (s5 !== peg$FAILED) {
                s6 = peg$parseidentifier();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseskip_space();
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s4;
                    s5 = peg$c450(s6);
                    s4 = s5;
                  } else {
                    peg$currPos = s4;
                    s4 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s4;
                  s4 = peg$FAILED;
                }
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            }
            if (s4 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c451(s4);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parselogging() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      s0 = peg$currPos;
      if (input.substr(peg$currPos, 7) === peg$c452) {
        s1 = peg$c452;
        peg$currPos += 7;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c453); }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$silentFails++;
        s3 = peg$parsechar();
        peg$silentFails--;
        if (s3 === peg$FAILED) {
          s2 = void 0;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s3 = peg$parseskip_space();
          if (s3 !== peg$FAILED) {
            s4 = peg$currPos;
            s5 = peg$parsevalue();
            if (s5 !== peg$FAILED) {
              peg$savedPos = peg$currPos;
              s6 = peg$c448(s5);
              if (s6) {
                s6 = void 0;
              } else {
                s6 = peg$FAILED;
              }
              if (s6 !== peg$FAILED) {
                peg$savedPos = s4;
                s5 = peg$c454(s5);
                s4 = s5;
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            } else {
              peg$currPos = s4;
              s4 = peg$FAILED;
            }
            if (s4 === peg$FAILED) {
              s4 = peg$currPos;
              s5 = peg$parseescape();
              if (s5 !== peg$FAILED) {
                s6 = peg$parseidentifier();
                if (s6 !== peg$FAILED) {
                  s7 = peg$parseskip_space();
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s4;
                    s5 = peg$c455(s6);
                    s4 = s5;
                  } else {
                    peg$currPos = s4;
                    s4 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s4;
                  s4 = peg$FAILED;
                }
              } else {
                peg$currPos = s4;
                s4 = peg$FAILED;
              }
            }
            if (s4 !== peg$FAILED) {
              s1 = [s1, s2, s3, s4];
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 7) === peg$c456) {
          s1 = peg$c456;
          peg$currPos += 7;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c457); }
        }
        if (s1 !== peg$FAILED) {
          s2 = peg$parsearg_group();
          if (s2 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c458(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }

      return s0;
    }


        var { Vector } = require('./types');
        var g = options.generator;
        g.setErrorFn(error);
        g.location = location;


    peg$result = peg$startRuleFunction();

    if (peg$result !== peg$FAILED && peg$currPos === input.length) {
      return peg$result;
    } else {
      if (peg$result !== peg$FAILED && peg$currPos < input.length) {
        peg$fail(peg$endExpectation());
      }

      throw peg$buildStructuredError(
        peg$maxFailExpected,
        peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
        peg$maxFailPos < input.length
          ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
          : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
      );
    }
  }

  return {
    SyntaxError: peg$SyntaxError,
    parse:       peg$parse
  };
})();